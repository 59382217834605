
var APIMapping = {
  loginURL: "/auth/login",
  logout: '/auth/logout',
  getAccessToken: `/auth/refresh-tokens`,
  addUser: `/users`,
  getUserByRole: `/users/role`,
  updateUserById: `/users/:id`,
  getUserById: `/users/:id`,
  getRoles: `/roles`,
  deleteUser: `/users/:id`,
  addUGroup: `/groups`,
  getGroups: `/groups`,
  getGroupType: `groups/list-by-user-type`,
  updateGroupById: `/groups/:id`,
  getGroupById: `/groups/:id`,
  deleteGroup: `/groups/:id`,
  logout: `/auth/logout`,
  databaseBase: `/database`,
  getBatchDetails: `/database/getbatchdetails`,
  dataBaseTables: `/database/dblisttables`,
  dataBaseColumns: `/database/dblistcolumns`,
  databaseConnect: `/database/dbconnect`,
  listBatches: `/database/listbatches`,
  storeDetails:`/database`,
  batchComparison:`/database/getbatchcomparisondetails`,
  groupByUserType: `/groups/list-by-user-type`,
  penlist: `/penlist`,
  groupPenDetail: `/groupPenDetail/:id`,
  updateGroupPenDetail: `/groupPenDetail/:id`,
  getAllRoles: `/roles`,

};
function getAPIMap(name) {

  return APIMapping[name];
}

export default getAPIMap;
