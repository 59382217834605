import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import getApiMap from '../../src/routes/url/ApiUrls'
import axios from "axios";

const useRefreshToken = () => {
    const { setAuth, auth, setSessionExpired } = useContext(AuthContext);

    const refresh = async () => {
        const storedToken = localStorage.getItem("refreshToken");
        try {
            const response = await axios.post(
                import.meta.env.VITE_SERVER_KRACKEND_URL +
                getApiMap["getAccessToken"],
                {
                    refresh_token: storedToken || auth?.refreshToken,
                }
            );

            const accessToken = response?.data?.token?.token;
            const refreshToken = response?.data?.refreshToken;

            setAuth((prev) => ({
                ...prev,
                accessToken,
                refreshToken,
            }));

            return accessToken;
        } catch (err) {

            throw err;
        }
    };

    return refresh;
};

export default useRefreshToken;
