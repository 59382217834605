import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart,
    LinearScale,
    CategoryScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler,
    TimeScale,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';

// Register necessary Chart.js components
Chart.register(
    LinearScale,
    CategoryScale,
    PointElement,
    LogarithmicScale,
    LineElement,
    Tooltip,
    Legend,
    Filler,
    zoomPlugin,
    TimeScale
);

// Custom plugin to draw a vertical line on hover
const intersectDataVerticalLine = {
    id: 'intersectDataVerticalLine',
    beforeDraw: (chart) => {
        const activeElements = chart.getActiveElements();
        if (activeElements.length) {
            const activePoint = activeElements[0];
            const chartArea = chart.chartArea;
            const ctx = chart.ctx;

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(activePoint.element.x, chartArea.top);
            ctx.lineTo(activePoint.element.x, chartArea.bottom);
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
            ctx.stroke();
            ctx.restore();
        }
    },
};

// Register the vertical line plugin
Chart.register(intersectDataVerticalLine);

// Custom plugin for displaying values on hover
const valueOnLinePlugin = {
    id: 'valueOnLine',
    afterDatasetsDraw: (chart) => {
        const { ctx, tooltip } = chart;

        if (tooltip && tooltip.active.length) {
            const activePoint = tooltip.active[0];
            const datasetIndex = activePoint.datasetIndex;
            const datasets = chart.data.datasets;

            ctx.save();
            const dataPoint = datasets[datasetIndex].data[activePoint.index];
            ctx.fillStyle = datasets[datasetIndex].borderColor;
            ctx.font = '12px Arial';
            ctx.fillText(dataPoint.y.toFixed(2), activePoint.element.x + 5, activePoint.element.y - 10);
            ctx.restore();
        }
    },
};

// Register the custom plugin
Chart.register(valueOnLinePlugin);

const CustomMultiLineChart = ({ visibleDatasets,minValues,maxValues, selectedFormats, lineWidths, getRandomColor, data, penColorMap, yAxisVisibility, yAxisDirection, padding = 10 }) => {
    const chartRef = useRef(null);

    const datasets = data.map((pen, index) => {
        if (visibleDatasets[index] && pen.penResult.length > 0) {
            const valueKey = Object.keys(pen.penResult[0]).find(k => k !== "DATE_TIME");
            const values = pen.penResult.map(result => {
                let value = result[valueKey];
                if (value) {
                    if (selectedFormats[index] === 'logarithmic') {
                        value = value > 0 ? Math.log10(value) : null; // Logarithmic transformation
                    } else if (selectedFormats[index] === 'exponent') {
                        value = Math.exp(value / 10); // Exponential transformation
                    }
                }
                return value;
            });

            const timestamps = pen.penResult.map(result => new Date(result.DATE_TIME));

            return {
                label: pen.penName,
                data: values.map((value, idx) => ({ x: timestamps[idx], y: value })),
                borderColor: penColorMap[pen.penName] || getRandomColor(),
                backgroundColor: penColorMap[pen.penName] || getRandomColor(),
                borderWidth: lineWidths[index] || 1,
                fill: false,
                tension: 0.4,
                yAxisID: `y-axis-${index}`,
            };
        }
        return null;
    }).filter(Boolean);

    const chartData = { datasets };
    const chartOptions = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                type: 'time',
                title: {
                    display: true,
                    text: 'Date Time',
                },
                time: {
                    tooltipFormat: 'dd/MM HH:mm',
                    unit: 'minute', // Set to minute level for zooming
                    displayFormats: {
                        minute: 'dd/MM HH:mm', // Show full date and time at minute level
                        hour: 'dd/MM HH:mm',   // Show full date and time at hour level
                        day: 'dd/MM',          // Show day for the day level
                    },
                },

            },
            ...datasets.reduce((acc, dataset, index) => {
                acc[dataset.yAxisID] = {
                    type: selectedFormats[index] === 'logarithmic' ? 'logarithmic' : 'linear',
                    ticks: {},
                    title: {
                        display: true,
                        text: dataset.label,
                    },
                    position: yAxisDirection[index] || 'left',
                    display: yAxisVisibility[index] !== false,
                    min: minValues[index] !== undefined ? minValues[index] : 0, // Use minValue for the dataset
                    max: maxValues[index] !== undefined ? maxValues[index] : undefined, 
                };
                return acc;
            }, {}),
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                backgroundColor: 'rgba(256, 256, 256, 1)',
                titleColor: "black",
                bodyColor: "black",
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset;
                        const dataPoint = dataset.data[tooltipItem.dataIndex];
                        // Format the date as per your requirement
                        const dateFormatted = new Date(dataPoint.x).toLocaleString('en-GB', { 
                            day: '2-digit', 
                            month: '2-digit', 
                            year: 'numeric', 
                            hour: '2-digit', 
                            minute: '2-digit' 
                        });
                        return `  ${dataset.label}:  ${dataPoint.y.toFixed(2)} at ${dateFormatted}`;
                    },
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'xy',
                },
            },
            intersectDataVerticalLine,
            valueOnLinePlugin,
        },
    };
    return (
        <div style={{ width: '100%', height: '800px', position: 'relative' }}>
            <Line 
                ref={chartRef} 
                data={chartData} 
                options={chartOptions} 
            />
        </div>
    );
};

export default CustomMultiLineChart;