import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const DownArrowIcon = ({ className, style }) => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        style={style}
    >
        <path
            d="M1.75 1.5L7.57226 6.21869C7.76995 6.37891 8.05643 6.3651 8.23779 6.18662L13 1.5"
            stroke="#737373"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

const MuiSelect = ({ label, labelClass, name, options, value, onChange, error, placeholder }) => {
    const [open, setOpen] = useState(false); // Track whether the select dropdown is open

    const handleOpen = () => {
        setOpen(true); // Set dropdown open state to true
    };

    const handleClose = () => {
        setOpen(false); // Set dropdown open state to false
    };

    return (
        <FormControl fullWidth sx={{ mb: 2, mr: 2 }} error={!!error}>
            <label className={`mb-2 ${labelClass} font-semibold rounded-md text-textHeading text-[14px]/[24px]`}>{label}</label>

            <Select
                name={name}
                value={value || ''}
                onChange={onChange}
                displayEmpty
                onOpen={handleOpen}
                onClose={handleClose}
                open={open}
                sx={{
                    paddingLeft: 0,
                    paddingRight: 2,
                    borderRadius: "8px",
                    borderColor: error ? 'red' : '#D5D7DA',
                    backgroundColor: 'white',
                    fontSize: '12px',
                    height: "44px",
                    outline: 'none',
                    color: "#979797",
                    '&:focus': {
                        borderColor: '#EB7E39', // Change border color on focus
                        boxShadow: '0 0 0 1px #EB7E39', // Add shadow if needed
                    },
                    '&:hover': {
                        borderColor: '#EB7E39',
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "1",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        border: 1,
                        borderColor: "#EB7E39"
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 1,
                        borderColor: "#EB7E39"
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 1,
                        borderColor: "#EB7E39", // Change border color when focused
                    },
                }}
                defaultValue=""
                IconComponent={(props) => (
                    <DownArrowIcon
                        {...props}
                        style={{
                            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                            top: "18px",
                            right: 20
                        }}
                    />
                )}
            >
                <MenuItem value="" disabled sx={{ color: "#555555", fontSize: "12px", borderColor: "#EB7E39", '&.Mui-disabled': { color: '#555555' } }}>
                    {placeholder}
                </MenuItem>

                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        sx={{ color: '#555555', fontSize: '12px', fontWeight: '12px', borderColor: "#EB7E39", '&:hover': { backgroundColor: 'rgba(255, 165, 0, 0.2)' } }} // Light orange background on hover

                    >
                        <span className='text-[#555555] text-[12px]'>{option.label}</span>
                    </MenuItem>
                ))}
            </Select>

            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default MuiSelect;
