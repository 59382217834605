import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import GroupInputField from '../../components/InputField/GroupInputField';
import Select from '../../components/SelectBox';
import { FirstIcon, SecondIcon, LineIcon, FirstCompletedIcon, SearchBoxIcon } from '../../assets/svgs';
import MultiSelect from '../SelectBox/MultiSelect';
import Notification from '../Notifications/index';

const AddGroup = ({ open, handleClose, disableBackdropClick, editGroupPenDetails }) => {
    const { fetchGroupTypes, fetchPen, addNewGroup, getGroupDetailsById, UpdateGroupPenDetail } = useContext(AuthContext);
    const [groupTypes, setGroupTypes] = useState([]);
    const [pens, setPens] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedPen, setSelectedPen] = useState(null);
    const [checkedPens, setCheckedPens] = useState({});
    const [addedPens, setAddedPens] = useState([]);
    const [groupId, setGroupId] = useState(null);
    const [availablePens, setAvailablePens] = useState([]);

    const [selectedPenDetails, setSelectedPenDetails] = useState({
        penName: '',
        min: null,
        max: null,
        format: '',
        logScale: false
    });
    const [selectedPenData, setSelectedPenData] = useState(null);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');
    const [groupPenDetails, setGroupPenDetails] = useState({
        minValue: '',
        maxValue: '',
        format: '',
        isLogScale: false,
        penName: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        min: false,
        max: false,
        format: false,
    });

    const [searchText, setSearchText] = useState('');

    const handlePenClick = (pen) => {
        // Set the selected pen value
        setSelectedPen(pen.value);

        // Find the selected pen data based on the pen value
        const selectedPenData = groupPenDetails.find((p) => p.pen_id === pen.value);
        setSelectedPenData(selectedPenData);

        // Set the selected pen details based on the found pen data
        if (selectedPenData && selectedPenData.penlist) {
            setSelectedPenDetails({
                penName: selectedPenData.penlist.pen_name,
                min: selectedPenData.min,
                max: selectedPenData.max,
                format: selectedPenData.format || '',
                logScale: selectedPenData.logscale || false,
            });
        } else {
            setSelectedPenDetails({
                penName: null,
                min: null,
                max: null,
                format: '',
                logScale: false,
            });
        }
    };

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    const handleModalClose = () => {
        resetFormState();
        setCurrentStep(1);
        handleClose(); // Call the passed handleClose function
    };

    useEffect(() => {
        if (currentStep === 2 && groupId) {
            const fetchPenDetails = async () => {
                try {
                    const penData = await getGroupDetailsById(groupId); // Fetch details
                    console.log("penData", penData)
                    setGroupPenDetails(penData.groupPenDetails || []); // Safely access and set the pen details
                    console.log("Fetched Pen Details:", penData.groupPenDetails);
                } catch (error) {
                    console.error("Failed to fetch pen details:", error);
                }
            };

            fetchPenDetails();
        }
    }, [currentStep, groupId]);

    const GroupPenOptions = Array.isArray(groupPenDetails)
        ? groupPenDetails.map((pen) => ({
            value: pen.pen_id,
            label: pen.penlist?.pen_name, // Add fallback in case pen_name is missing
        }))
        : [];

    const formatOptions = [
        { value: 'real', label: 'Real' },
        { value: 'integer', label: 'Integer' },
        { value: 'exponential', label: 'Exponential' },
    ];


    const handleAddClick = () => {
        let localErrorMessage = '';
        let hasError = false;

        // Validation checks
        if (!selectedPenData.min || !selectedPenData.max || !selectedPenData.format) {
            localErrorMessage = 'All fields must be filled!';
            hasError = true;
            setValidationErrors({
                min: !selectedPenData.min,
                max: !selectedPenData.max,
                format: !selectedPenData.format,
            });
        } else {
            // Clear previous validation errors if inputs are filled
            setValidationErrors({ min: false, max: false, format: false });

            if (Number(selectedPenData.max) <= Number(selectedPenData.min)) {
                localErrorMessage = 'Max value must be greater than Min value!';
                hasError = true;
                setValidationErrors({ min: false, max: true, format: false });
            }
        }

        if (hasError) {
            setErrorMessage(localErrorMessage);
            return;
        }

        if (selectedPenData?.penlist) {

            // Add the pen if all checks pass
            const existingPen = addedPens.find(pen => pen.id === selectedPenData.id);
            if (!existingPen) {
                setAddedPens((prevPens) => [
                    ...prevPens,
                    {
                        id: selectedPenData.id,
                        pen_name: selectedPenData.penlist.pen_name,
                        min: selectedPenData.min || null,
                        max: selectedPenData.max || null,
                        format: selectedPenData.format || null,
                        logScale: selectedPenData.logScale || false,
                    },
                ]);
                setAvailablePens((prevPens) =>
                    prevPens.filter(pen => pen.label !== selectedPenData.penlist.pen_name)
                );
                // Clear the input fields after adding
                setSelectedPenData({
                    penlist: {
                        pen_name: '',
                    },
                    min: '',
                    max: '',
                    format: '',
                    logScale: false,
                });
                setErrorMessage('');
            } else {
                setErrorMessage('This pen has already been added.'); // Display the error message
                setTimeout(() => {
                    setErrorMessage(''); // Clear the message
                }, 2000);
            }
        }
    };


    const handleSaveClick = async () => {
        try {

            console.log("addedPens", addedPens)
            console.log("groupPenDetail", groupPenDetails)

            const updatePromises = addedPens.map(async (addedPen) => {
                const groupPenDetail = groupPenDetails.find(pen => pen.id === addedPen.id);
                console.log("groupPenDetail", groupPenDetail)

                if (!groupPenDetail) return null;

                const updatedPen = {
                    min: addedPen.min,
                    max: addedPen.max,
                    format: addedPen.format,
                    logScale: addedPen.logScale,
                };

                try {
                    return await editGroupPenDetails(groupPenDetail.id, updatedPen);
                } catch (updateError) {
                    console.error(`Failed to update pen ID ${groupPenDetail.id}:`, updateError);
                    return null;
                }
            });

            const responses = await Promise.all(updatePromises);

            setGroupPenDetails((prevDetails) =>
                prevDetails.map((pen) => {
                    const updatedResponse = responses.find(response => response && response.id === pen.id);
                    if (updatedResponse) {
                        return {
                            ...pen,
                            min: updatedResponse.min || pen.min,
                            max: updatedResponse.max || pen.max,
                            format: updatedResponse.format || pen.format,
                            logScale: updatedResponse.logScale || pen.logScale,
                            penlist: { pen_name: updatedResponse.penlist?.pen_name || pen.penlist.pen_name },
                        };
                    }
                    return pen;
                })
            );

            setNotificationMessage('Group pen details updated successfully. Check the group list.');
            setNotificationSeverity('success');

            resetFormState();
            setCurrentStep(1);
            handleClose();

        } catch (error) {
            setCurrentStep(1);
            handleClose();
        }
    };

    const resetFormState = () => {
        setAddedPens([]);
        setGroupPenDetails([]);
        formik.resetForm(); // If you're using Formik, this resets the values in the form
        setNotificationMessage(''); // Clear previous notifications if necessary
        setNotificationSeverity(''); // Clear previous severity
    };

    const validationSchema = Yup.object().shape({
        groupName: Yup.string()
            .matches(/^(?=.*[A-Za-z0-9])[ A-Za-z0-9]*$/, "Groupname must be alphanumeric")
            .max(64, 'Group Name must be at most 64 characters')
            .required('Group Name is required'),
        groupType: Yup.string().required('Group Type is required'),
        penList: Yup.array().min(1, 'At least one pen must be selected').required('Penlist is required'),
        defaultPen: Yup.string().required('Default Pen is required'),
    });


    useEffect(() => {
        const loadGroupTypes = async () => {
            const types = [
                { value: 'admin', label: 'Admin' },
                { value: 'public', label: 'Public' },
                { value: 'private', label: 'Private' },
            ];
            setGroupTypes(types);
        };

        loadGroupTypes();
    }, []);

    useEffect(() => {
        const loadPens = async () => {
            setLoading(true);
            try {
                const data = await fetchPen();
                setPens(data || []);
            } catch (err) {
                setError('Error fetching pens');
            } finally {
                setLoading(false);
            }
        };

        loadPens();
    }, [fetchPen]);



    const handleRemove = (penId) => {
        setAddedPens(addedPens.filter(pen => pen.id !== penId));
        // const removedPen = addedPens.find(pen => pen.id === penId);
        // if (removedPen) {
        //     setAvailablePens((prevPens) => [
        //         ...prevPens,
        //         { value: removedPen.id, label: removedPen.pen_name },
        //     ]);
        // }
    };

    const filteredPens = availablePens.filter(pen =>
        pen.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const PenOptions = pens.map(pen => ({
        value: pen.id,
        label: pen.pen_name,
    }));

    const formik = useFormik({
        initialValues: {
            groupName: '',
            groupType: '',
            penList: [],
            defaultPen: '',
        },
        validationSchema,
    });

    const renderLabel = (label, isRequired = false) => (
        <div className="text-[14px] font-semibold">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
        </div>
    );

    const renderPenLabel = (label, isRequired = false) => (
        <div className="text-[12px] font-semibold text-[#191919]">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
        </div>
    );



    const renderFirstStep = () => (
        <>
            <div className="flex items-center justify-center mb-4">
                {currentStep >= 2 ? <FirstCompletedIcon /> : <FirstIcon />}
                <LineIcon className="mx-2" />
                <SecondIcon />
            </div>
            {renderLabel('Group Name', true)}
            <GroupInputField
                type="text"
                placeholder="Enter the group name"
                className={`border rounded-md p-2 w-full mb-2 text-[12px] text-[#555555] ${formik.touched.groupName && formik.errors.groupName ? 'border-red-500' : 'border-gray-300'}`}
                value={formik.values.groupName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="groupName"
                labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]/[24px]"
            />
            {formik.touched.groupName && formik.errors.groupName && (
                <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.groupName}</div>
            )}

            {renderLabel('Group Type', true)}
            <Select
                name="groupType"
                value={formik.values.groupType}
                placeholder="Select group type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={groupTypes}
                labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]/[24px]"
                className={`w-full p-3 h-[44px] border rounded-md  text-[12px] text-[#555555]  ${formik.touched.groupType && formik.errors.groupType ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.groupType && formik.errors.groupType && (
                <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.groupType}</div>
            )}

            {renderLabel('Penlist', true)}
            <MultiSelect
                name="penList"
                options={PenOptions}
                value={formik.values.penList}
                onChange={(selectedPens) => {
                    if (selectedPens.length > 10) {
                        return; // Prevent setting values if limit is exceeded
                    }
                    formik.setFieldValue("penList", selectedPens); // Set the selected pens
                }}
                placeholder="Select Pens from penlist"
            />
            {formik.touched.penList && formik.errors.penList && (
                <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.penList}</div>
            )}

            {renderLabel("Default Pen", true)}
            <Select
                name="defaultPen"
                value={formik.values.defaultPen}
                placeholder={"Select default pen"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={formik.values.penList.map(penId => {
                    const pen = PenOptions.find(option => option.value === penId);
                    return pen ? { value: pen.value, label: pen.label } : null;
                }).filter(option => option !== null)}
                className={`w-full p-3 h-[44px] border rounded-md text-[12px] text-[#555555] ${formik.touched.defaultPen && formik.errors.defaultPen ? 'border-red-500' : 'border-gray-300'
                    }`}
                labelClass="text-textHeading text-[14px] font-semibold"
                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]"
            />
            {formik.touched.defaultPen && formik.errors.defaultPen && (
                <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.defaultPen}</div>
            )}
            <div className="flex justify-end mt-0 mb-0">
                <button
                    type="button"
                    className="flex justify-center items-center py-2 px-4 h-[40px] bg-white border border-[#A9A3A3] text-[16px] text-[#555555] rounded-md mr-2 hover:bg-gray-100"
                    onClick={handleModalClose} // Call handleModalClose on click
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className={`flex justify-center items-center py-2 px-1 h-[40px] bg-baseOrange w-48 text-[16px] text-white rounded-md `}
                    onClick={async () => {
                        const errors = await formik.validateForm();
                        formik.setTouched({
                            groupName: true,
                            groupType: true,
                            penList: true,
                            defaultPen: true,
                        });
                        if (Object.keys(errors).length === 0) {
                            try {
                                const groupDetails = {
                                    name: formik.values.groupName,
                                    penIds: formik.values.penList,
                                    type: formik.values.groupType,
                                    defaultPenId: formik.values.defaultPen,
                                };

                                const response = await addNewGroup(groupDetails);
                                console.log("Group added successfully:", response);
                                setNotificationMessage('Group added successfully. Check group list.');
                                setNotificationSeverity('success');
                                const groupId = response?.groupDetail?.id;
                                if (groupId) {
                                    setGroupId(groupId);
                                    console.log("Group ID stored in state:", groupId);
                                }
                                setCurrentStep(2);
                            } catch (error) {
                                console.error("Error adding group:", error);
                            }
                        }
                    }}>
                    Save and Next
                </button>
            </div>
        </>
    );

    const handleInputChange = (field, value) => {
        setSelectedPenData(prevData => {
            const updatedData = { ...prevData, [field]: value };
            if (field === 'format') {
                updatedData.logScale = value === 'exponential';
            }
            return updatedData;
        });
    };

    const handleLogToggle = () => {
        setSelectedPenData(prevData => ({
            ...prevData,
            logScale: !prevData.logScale,
        }));
    };

    // const filteredPens = GroupPenOptions.filter(pen =>
    //     pen.label.toLowerCase().includes(searchText.toLowerCase())
    // );

    const renderSecondStep = () => (
        <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-center mb-1">
                <FirstCompletedIcon className="text-[#EB7E39]" />
                <LineIcon className="mx-2 text-[#EB7E39]" />
                <SecondIcon className="text-[#EB7E39]" />
            </div>

            <div className="flex justify-between">
                {/* Available Pens */}
                <div className="flex flex-col ml-[-20px] w-1/4">
                    <div className="mb-2 flex justify-center text-[14px] text-[#555555] font-medium">
                        Available Pens
                    </div>
                    <div className="border border-gray-400 rounded-md w-[205px] h-[271px] overflow-hidden">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search pen name"
                                className="w-full p-2 rounded-[5px] text-[12px] focus:outline-none focus:ring focus:ring-orange-300"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                                <SearchBoxIcon /> {/* Use your search icon here */}
                            </div>
                        </div>
                        <div className="border-b border-gray-400 mb-1"></div> {/* Space below the input */}

                        <ul className="h-[232px] overflow-y-auto">
                            {GroupPenOptions.map((pen, index) => (
                                <li
                                    key={pen.value}
                                    onClick={() => handlePenClick(pen)}
                                    className={`flex flex-col p-2 text-[#555555] text-[12px] cursor-pointer ${selectedPen === pen.value ? 'bg-[#F5F5F5]' : ''
                                        } hover:bg-[#F5F5F5`}
                                >
                                    <span className="flex justify-between ml-2">{pen.label}</span>
                                    {index < GroupPenOptions.length && (
                                        <div className="border-b border-[#F5F5F5] w-5/6 mx-auto mt-1"></div> // Short, centered line
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Selected Pen Details */}
                <div className="flex flex-col w-1/3">
                    <div className="mb-2 flex justify-center text-[14px] text-[#555555] font-medium">
                        Selected Pen Details
                    </div>
                    <div className="border border-gray-400 rounded-md p-4 w-[272px] h-[272px] ml-[8px]">
                        <div className="grid grid-cols-[94px_1fr]">
                            {/* Pen Name */}
                            <div className="flex items-center">{renderPenLabel('Pen Name', false)}</div>
                            <input
                                type="text"
                                value={selectedPenData?.penlist?.pen_name || ''}
                                readOnly
                                className={`border rounded-md w-full text-[#555555] text-[12px] p-1 h-[32px] border-borderColorBox focus:border-[#EB7E39] focus:outline-none`} // Added outline-none to remove unwanted outlines
                                placeholder="Pen Name"
                                labelClass="text-textHeading text-[12px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]/[24px]"

                            />

                            {/* Min */}
                            <div className="flex items-center">{renderPenLabel('Min', false)}</div>
                            <input
                                type="text"
                                value={selectedPenData?.min || ''} // Display min value
                                className={`border rounded-md w-full text-[#555555] text-[12px] mt-2 p-1 h-[32px] border-borderColorBox ${validationErrors.min ? 'border-red-500' : 'border-[#D5D7DA]'} focus:border-[#EB7E39] focus:outline-none`} // Added outline-none to remove unwanted outlines
                                onChange={(e) => handleInputChange('min', e.target.value.replace(/[^0-9]/g, ''))} // Allow only numbers
                                placeholder="Enter min value"
                            />

                            {/* Max */}
                            <div className="flex items-center">{renderPenLabel('Max', false)}</div>
                            <input
                                type="text"
                                value={selectedPenData?.max || ''} // Display max value
                                onChange={(e) => handleInputChange('max', e.target.value.replace(/[^0-9]/g, ''))} // Allow only numbers
                                placeholder="Enter max value"
                                className={`border rounded-md w-full text-[#555555] text-[12px] mt-2 p-1 h-[32px] border-borderColorBox ${validationErrors.max ? 'border-red-500' : 'border-[#D5D7DA]'} focus:border-[#EB7E39] focus:outline-none`} // Added outline-none to remove unwanted outlines
                                labelClass="text-textHeading text-[12px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]/[24px]"
                            />


                            {/* Format */}

                            <div className="flex items-center">{renderPenLabel('Format', false)}</div>
                            <Select
                                name="format"
                                options={formatOptions}
                                value={selectedPenData?.format || ''} // Display format value
                                onChange={(e) => handleInputChange('format', e.target.value)}
                                placeholder="Select format"
                                className="border rounded-md w-[10px] text-[12px]/[24px] text-[#555555]"
                                labelClass="text-textHeading text-[12px]/[20px] font-semibold"
                                inputClass="p-1 h-[32px] border border-borderColorBox rounded-md text-[#555555] text-[12px]/[24px]"
                            />
                            {errorMessage && (
                                <span className="text-red-500 text-xs col-span-2">{errorMessage}</span>
                            )}
                        </div>


                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <span className="text-[#191919] text-[12px] mr-2">Log Scale</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only"
                                        checked={selectedPenData?.logScale || false}
                                        readOnly // Prevent user interaction
                                    />
                                    {/* Background of the switch */}
                                    <div className={`w-[21px] h-[12px] rounded-full shadow-inner transition ${selectedPenData?.logScale ? 'bg-green-500' : 'bg-gray-200'}`}></div>
                                    {/* Dot of the switch */}
                                    <div
                                        className={`dot absolute bg-white w-[10px] h-[10px] rounded-full transition transform ${selectedPenData?.logScale ? 'translate-x-2.5' : 'translate-x-0.5'}`}
                                    >
                                    </div>
                                </label>

                            </div>
                            <button
                                className="px-4 py-2 bg-white text-[#EB7E39] border border-[#EB7E39] rounded-md hover:bg-[#EB7E39] hover:text-white transition"
                                onClick={handleAddClick}
                            >
                                + Add
                            </button>
                        </div>
                    </div>
                </div>

                {/* Added Pens */}
                <div className="w-1/3">
                    <div className="mb-2 flex justify-center text-[14px] text-[#555555] font-medium">
                        Added Pens
                    </div>
                    <div className="border border-gray-400 rounded-md p-2  w-[218px] h-[272px] ml-[25px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thumb-rounded-full">
                        {addedPens.length === 0 ? (
                            <p className="text-gray-500 text-sm text-center"></p>
                        ) : (
                            addedPens.map((pen, index) => (

                                <div key={pen.id} className="items-center bg-[#D9D9D9] rounded-md px-2 mt-1 py-1 text-sm mr-1 text-[#555555] inline-block">

                                    <span>{pen.pen_name}</span>
                                    <button
                                        className="ml-1 text-[#555555] font-bold focus:outline-none"
                                        onClick={() => handleRemove(pen.id)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            <div className="flex justify-end mt-4 space-x-4">
                <button onClick={() => setCurrentStep(1)} className="border text-gray-600 px-4 py-2 rounded">
                    Back
                </button>
                <button className="border bg-orange-500 text-white px-4 py-2 rounded" onClick={handleSaveClick}>
                    Save
                </button>
            </div>
        </div>
    );


    const modalWidth = currentStep === 1 ? 'w-5/12' : 'w-[743px] h[548px]';
    return (
        <>
            <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center ${open ? '' : 'hidden'}`} onClick={disableBackdropClick ? null : handleClose}>
                <div className={`${modalWidth} bg-white p-8 rounded-[20px] shadow-lg mt-2 overflow-y-auto max-h-[97vh`} onClick={(e) => e.stopPropagation()}>
                    <div className="relative flex flex-col">
                        <button onClick={() => { handleModalClose(); }} className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl">
                            &times;
                        </button>

                        <div className="text-textHeading2 text-[16px]/[28px] font-bold mt-2">Add Group</div>

                        {/* Conditional Heading Logic */}
                        {currentStep === 1 ? (
                            <div className="text-[#737373] text-[12px]/[20px] pt-1 mb-4">You can add a maximum of 10 pens in a group.</div>
                        ) : (
                            <div className="text-[#737373] text-[12px]/[20px] pt-1 mb-4">Save details for all the listed pens.</div>
                        )}

                    </div>

                    {currentStep === 1 ? renderFirstStep() : renderSecondStep()}
                </div>
            </div>
            <Notification
                open={notificationOpen}
                message={notificationMessage}
                onClose={() => setNotificationOpen(false)} // Make sure to reset open state
                severity={notificationSeverity}
            />
        </>
    );
};

export default AddGroup;
