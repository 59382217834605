import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SidebarMenu = ({
    data,
    visibleDatasets,
    handleToggleDataset,
    colors,
    handleChangeColor,
    handleChangeMinMax,
    lineWidths,
    penColorMap,
    handleChangeLineWidth,
    selectedFormat,
    handleChangeFormat,
    logScale,
    yAxisDirection = [],
    handleChangeYAxisDirection,
    handleToggleLogScale,
    scaleVisible,
    yAxisVisibility,
    handleToggleScaleVisible,
}) => {
    const handleExpandToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const [expandedIndex, setExpandedIndex] = useState(null); // Track which dataset's details are expanded
    const [minValues, setMinValues] = useState([]); // State for min values
    const [maxValues, setMaxValues] = useState([]); // State for max values

    // Function to calculate the min and max values for a penResult array dynamically
    const calculateMinMax = (penResult) => {
        // Dynamically find the first numeric field in penResult
        const numericFields = penResult
            .map(entry => {
                // Check if any field in the entry is numeric
                for (const key in entry) {
                    if (!isNaN(entry[key])) {
                        return entry[key]; // Return the first numeric field found
                    }
                }
                return null; // If no numeric field, return null
            })
            .filter(value => value !== null); // Remove null values
        if (numericFields.length > 0) {
            const min = Math.min(...numericFields);
            const max = Math.max(...numericFields);
            return { min, max };
        }

        return { min: 0, max: 0 }; // Default values if no valid numeric field
    };

    // Initialize the min and max values when the data changes
    useEffect(() => {
        if (data && data.length > 0) {
            const newMinValues = data.map((dataset) => {
                // For each dataset, calculate the min and max of the penResult array
                if (dataset.penResult && dataset.penResult.length > 0) {
                    return calculateMinMax(dataset.penResult);
                }
                return { min: 0, max: 0 }; // Default values if no data exists
            });
            setMinValues(newMinValues.map(value => value.min));
            setMaxValues(newMinValues.map(value => value.max));
        }
    }, [data]);

    const handleNumericChange = (e, index, type) => {
        const value = e.target.value;
        if (isNaN(value) || value === '') return; // Avoid invalid input

        const updatedValues = type === 'min' ? [...minValues] : [...maxValues];
        updatedValues[index] = parseFloat(value); // Update the value at the given index

        type === 'min' ? setMinValues(updatedValues) : setMaxValues(updatedValues);

        handleChangeMinMax(index, type, parseFloat(value)); // Pass index, type ('min'/'max') and the new value
    };
    const handleYAxisDirectionChange = (e, index) => {
        const newDirection = e.target.value;
        handleChangeYAxisDirection(newDirection, index);
    }
    console.log(minValues,maxValues)
    return (
     
     <div style={{ flex: '0 0 15%', borderLeft: '1px solid #ccc', height: '100%', padding: '10px' }} className="bg-[#F4F4F4]">
            {data?.length > 0 ? (
                data.map((entry, index) => (
                    <div key={entry.id} style={{ marginBottom: '8px' }} className="border-b border-[#F1F5F9] pb-2">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "between" }} className="justify-between items-center">
                            <label className="flex items-center gap-x-2 justify-between  bg-[#F4F4F4]">
                                <div className="flex items-center justify-between">
                                    <div
                                        className="dot"
                                        style={{
                                            backgroundColor: penColorMap[entry.penName],
                                            width: '16px',
                                            height: '16px',
                                            borderRadius: '100%',
                                            display: 'inline-block',
                                            marginLeft: '10px',
                                        }}
                                    />
                                    <div style={{ marginLeft: '10px', color: '#000' }} className="w-full">
                                        {entry.penName}
                                    </div>
                                    <div
                                        style={{
                                            width: '50px', // Increased width for a more spacious toggle
                                            height: '20px', // Slightly taller for better touch target
                                            backgroundColor: visibleDatasets[index] ? penColorMap[entry.penName] || '#d5d5d5' : '#d5d5d5', // Background color based on visibility
                                            borderRadius: '12px', // Rounded corners for the toggle
                                            position: 'relative',
                                            transition: 'background-color 0.3s',
                                            cursor: 'pointer',
                                            marginLeft: '10px',
                                        }}
                                        onClick={() => handleToggleDataset(index)}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '0px', // Centered vertically in the toggle
                                                left: visibleDatasets[index] ? '26px' : '2px', // Adjusted position based on visibility
                                                width: '18px', // Size of the toggle knob
                                                height: '18px', // Size of the toggle knob
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                                transition: 'left 0.3s ease',
                                            }}
                                        />
                                    </div>
                                </div>


                                <div
                                    onClick={() => handleExpandToggle(index)}
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    className="flex justify-end w-full right-0"
                                >
                                    {expandedIndex === index ? (
                                        <KeyboardArrowDownIcon className="w-5 h-5" />
                                    ) : (
                                        <KeyboardArrowRightIcon className="w-5 h-5" />
                                    )}
                                </div>
                            </label>
                        </div>
                        {
                            expandedIndex === index && (
                                <div style={{ marginTop: '5px' }} className="w-full">
                                   <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
    <label className="flex items-center">Min:</label>
    <input
        type="text"
        value={minValues[index] || ''}
        onChange={(e) => handleNumericChange(e, index, 'min')}
        className="flex justify-center items-center focus:outline-none"
    />
</div>
<div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
    <label className="flex items-center">Max:</label>
    <input
        type="text"
        value={maxValues[index] || ''}
        onChange={(e) => handleNumericChange(e, index, 'max')}
        className="flex justify-center items-center focus:outline-none"
    />
</div>
                                    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Fill Colour</label>
                                        <input
                                            type="color"
                                            value={penColorMap[entry?.penName] || '#000000'}
                                            onChange={(e) => handleChangeColor(index, e.target.value)}
                                            style={{
                                                backgroundColor: '#F0F5EC',
                                                width: '40%',
                                                borderRadius: '2px',
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                padding: '0px',
                                            }}
                                            className="flex justify-center items-center focus:outline-none"
                                        />
                                    </div>
                                    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Line Size</label>
                                        <input
                                            type="text"
                                            min="1"
                                            value={lineWidths[index] || 1}
                                            onChange={(e) => handleChangeLineWidth(index, e.target.value)}
                                            style={{
                                                backgroundColor: '#F0F5EC',
                                                width: '40%',
                                                borderRadius: '2px',
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                padding: '0px',
                                            }}
                                            className="flex justify-center items-center focus:outline-none"
                                        />
                                    </div>
                                    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label htmlFor="format-select" className="flex items-center">Format:</label>
                                        <select
                                            id="format-select"
                                            value={selectedFormat}
                                            onChange={(e) => handleChangeFormat(index,e.target.value)}
                                            style={{ width: '60%' }}
                                            className="bg-[#F0F5EC] focus:outline-none"
                                        >
                                            <option value="exponent">Exponent</option>
                                            <option value="linear">Linear</option>
                                            <option value="logarithmic">Logarithmic</option>
                                        </select>
                                    </div>
                                    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Log Scale</label>
                                        <div className="pr-2 flex items-center bg-[#D9DDE066]">
                                            <input
                                                type="checkbox"
                                                checked={logScale}
                                                onChange={handleToggleLogScale}
                                                className="w-5 h-5 bg-[#D9DDE066] pr-2 focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Scale Visible</label>
                                        <div className="pr-2 flex items-center bg-[#D9DDE066]">
                                            <input
                                                type="checkbox"
                                                checked={yAxisVisibility[index]}
                                                onChange={() => handleToggleScaleVisible(index)}
                                                className="w-5 h-5 bg-[#D9DDE066] pr-2 focus:outline-none"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Display area</label>
                                        <div className="pr-2 flex items-center bg-[#D9DDE066]">
                                            <select
                                                id="format-select"
                                                value={yAxisDirection[index]} // Correctly tied to the state
                                                onChange={(e) => handleChangeYAxisDirection(e.target.value, index)} // Adjust here
                                                className="bg-[#F0F5EC] focus:outline-none"
                                            >
                                                <option value="left">Left</option>
                                                <option value="right">Right</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div >
                ))
            ) : (
                <p>No datasets available.</p>
            )}
        </div >
    );
};

export default SidebarMenu;