import React, { useEffect, useState, useCallback } from 'react';
import NoFiltersScreen from '../components/NoFiltersScreen';
import BatchComparisonNavbar from '../components/BatchComparisonNavbar';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getAPIMap from '../routes/url/ApiUrls';
import BatchComparisonGraph from '../components/BatchComparisonGraph';
import BatchComparisonSidebar from '../components/BatchComparisonSidebar'
const BatchComparison = () => {
  const [data, setData] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [batchOptionSelected, setBatchOptionsSelected] = useState([]);
  const [batchesSent, setBatchesSent] = useState([]);
  const [listData, setListData] = useState([])
  const [showLogScale, setShowLogScale] = useState(false)
  const [minValues, setMinValue] = useState(null)
  const [dataResponse, setDataResponse] = useState(false)
  const [maxValue, setMaxValue] = useState(null)
  const [minMaxValue, setMinMaxValue] = useState({})
  const [yAxisFormat, setYAxisFormat] = useState('linear');
  const axiosPrivate = useAxiosPrivate();
  const [dateRange, setDateRange] = useState([null, null]);
  const [groupList, setGroupList] = useState([]);
  const [groupOptionSelected, setGroupOptionsSelected] = useState([]);
  const usedColors = new Set();
  const generateRandomDarkColor = () => {
    // Function to generate a random RGB color and check if it has been used before
    const randomColor = () => Math.floor(Math.random() * 156); // Generates a random number for RGB
    let color;

    do {
      color = `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`;
    } while (usedColors.has(color)); // Keep generating until a unique color is found

    usedColors.add(color); // Add the newly generated unique color to the Set

    return color; // Return the unique color
  }
  const fetchBatchList = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(getAPIMap("listBatches"));
      const batches = response?.data?.data?.map(batch => ({
        label: batch.id,
        value: batch.id,
        details: batch,
      })) || [];
      setBatchList(batches);
    } catch (error) {
      console.error("Error fetching batch list:", error);
    }
  }, [axiosPrivate]);
  console.log(groupOptionSelected)
  const handleFetchData = useCallback(async () => {
    if (batchOptionSelected.length === 0 || !dateRange[0] || !dateRange[1]) return;
    console.log(groupOptionSelected)
    const body = {
      penId: groupOptionSelected[0]?.value?.toString(),
      startDateTime: dateRange[0],
      endDateTime: dateRange[1],
      batchNumbers: batchOptionSelected.map(batch => JSON.stringify(batch)),
    };

    try {
      const response = await axiosPrivate.post(getAPIMap("batchComparison"), body);
      const responseData = response.data.data;
      console.log(response)
      console.log(responseData);
      if (response.data.data.length === 0) {
        setDataResponse(true)
        return;
      } else {
        setData(responseData);
        setDataResponse(false)
      }


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [batchOptionSelected, dateRange, groupList, axiosPrivate]);
  const fetchGroupList = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(getAPIMap("groupByUserType"));
      const groups = response?.data?.map(group => ({
        label: group.name,
        value: group.id,
        penIds: group.penList,
      })) || [];
      setGroupList(groups);
    } catch (error) {
      console.error("Error fetching group list:", error);
    }
  }, [axiosPrivate]);

  useEffect(() => {
    fetchBatchList();
    fetchGroupList();
  }, [fetchBatchList, fetchGroupList]);

  useEffect(() => {
    if (data.length > 0) {
      const modifiedBatchData = data.map((data) => {
        return {
          ...data,
          color: generateRandomDarkColor(),
          lineWidth: 1,
          errorband: data.isMasterBatch ? 5 : null,
          visibility: true,
        };
      });

      console.log(modifiedBatchData);
      setBatchesSent(modifiedBatchData);
      const minMaxValues = {};

      data.forEach(batch => {
        if (batch.batchResult && Array.isArray(batch.batchResult)) {
          batch.batchResult.forEach(result => {
            Object.keys(result).forEach(key => {
              if (key !== "DATE_TIME" && key !== "CHARGE_NUMBER" && key !== "minutesPassed") {
                const value = result[key];
                console.log(value);
                if (!minMaxValues[key]) {
                  minMaxValues[key] = { min: value, max: value }; // Set both min and max to the first value found
                } else {

                  minMaxValues[key].min = Math.min(minMaxValues[key].min, value);
                  minMaxValues[key].max = Math.max(minMaxValues[key].max, value);
                  setMaxValue(Math.max(minMaxValues[key].max, value))
                  setMinValue(Math.min(minMaxValues[key].min, value))
                }
              }
            });
          });
        }
      });

      setMinMaxValue(minMaxValues);
      console.log("Min and Max Values for each unique key:", minMaxValues);

    }
  }, [data]);
  const handleReset = () => {
    setData([]);
    setBatchOptionsSelected([]);
    setGroupOptionsSelected([]);
    setDateRange([null, null]);
    setBatchesSent([]); // Reset batchesSent as well
  };

  const toggleVisibility = (index) => {
    setBatchesSent(prevBatches => {
      const updatedBatches = [...prevBatches];
      updatedBatches[index].visibility = !updatedBatches[index].visibility; // Toggle the visibility value
      return updatedBatches;
    });
  };
  const handleMinValueChange = (event) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) setMinValue(value);
  };

  const handleMaxValueChange = (event) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) setMaxValue(value);
  };

  const handleYAxisFormatChange = (event) => {
    setYAxisFormat(event.target.value);

  };
  const handleColorChange = (index, event) => {
    const newColor = event.target.value;
    setBatchesSent(prevBatches => {
      const updatedBatches = [...prevBatches];
      updatedBatches[index].color = newColor; // Toggle the visibility value
      return updatedBatches;
    });
  };

  const handleLineWidthChange = (index, event) => {
    const newLineWidth = parseInt(event.target.value.replace('px', ''), 10);
    if (isNaN(newLineWidth)) { return; }
    setBatchesSent(prevBatches => {
      const updatedBatches = [...prevBatches];
      updatedBatches[index].lineWidth = newLineWidth; // Toggle the visibility value
      return updatedBatches;
    });
  };
  const handleShowLogScale = () => {
    setShowLogScale(!showLogScale)
  }
  return (
    <div className="bg-white w-full mb-40 rounded-r-md rounded-bl-md rounded-l-none">
      <BatchComparisonNavbar
        batchList={batchList}
        batchOptionSelected={batchOptionSelected}
        setBatchOptionsSelected={setBatchOptionsSelected}
        dateRange={dateRange}
        setDateRange={setDateRange}
        groupData={batchesSent}
        groupList={groupList}
        toggleVisibility={toggleVisibility}
        groupOptionSelected={groupOptionSelected}
        setGroupOptionsSelected={setGroupOptionsSelected}
        handleFetchData={handleFetchData}
        handleReset={handleReset}
        data={data}
        minMaxValue={minMaxValue}
      />
      <div className="mt-4 flex flex-row">
        {data.length === 0 ? (
          <NoFiltersScreen label={dataResponse?'No data found for the relevant search':'Add filters to generate graph!'} />
        ) : (
          <div className='flex w-full h-[60vh]'>
            <div className='w-9/12 h-full'>
              <BatchComparisonGraph
                data={data}
                setListData={setListData}
                batchesSent={batchesSent}
                showLogScale={showLogScale}
                yAxisFormat={yAxisFormat}
                setMinValue={setMinValue}
                setMaxValue={setMaxValue}
                minValues={minValues} maxValue={maxValue}
              />
            </div>
            <div className='flex-1 h-full'>
              <BatchComparisonSidebar
                minMaxValue={minMaxValue}
                listData={listData}
                handleShowLogScale={handleShowLogScale}
                showLogScale={showLogScale}
                toggleVisibility={toggleVisibility}
                handleYAxisFormatChange={handleYAxisFormatChange}
                handleMaxValueChange={handleMaxValueChange}
                handleMinValueChange={handleMinValueChange}
                minValues={minValues}
                maxValue={maxValue}
                handleColorChange={handleColorChange}
                handleLineWidthChange={handleLineWidthChange}
                yAxisFormat={yAxisFormat}
                batchesSent={batchesSent} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BatchComparison;