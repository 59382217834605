import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register required Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, LogarithmicScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin);

const FurnaceChart = ({ data, setListData, batchesSent, setMinValue, setMaxValue, minValues, maxValue, yAxisFormat, showLogScale }) => {
    const prepareChartData = (data) => {
        const datasets = [];
        const labelSet = new Set();

        // Filter for visible batches
        const visibleBatches = batchesSent.filter(batch => batch.visibility);

        visibleBatches.forEach(batch => {
            const batchLabel = `Batch ${batch.label}`;
            const temperatures = [];
            const correspondingBatch = data.find(d => d.batchNumber.toString() === batch.batchNumber.toString());

            if (correspondingBatch) {
                correspondingBatch.batchResult.forEach(result => {
                    const timeLabel = result.minutesPassed; // Using this for x-axis
                    labelSet.add(timeLabel); // Add time label for x-axis

                    // Collect temperature values as per keys in data
                    Object.keys(result).forEach(key => {
                        if (key !== "DATE_TIME" && key !== "CHARGE_NUMBER" && key !== "minutesPassed") {
                            let temperatureValue = result[key];
                            
                            // Transform temperature based on yAxisFormat
                            if (yAxisFormat === 'logarithmic') {
                                temperatureValue = temperatureValue > 0 ? Math.log10(temperatureValue) : null;
                            } else if (yAxisFormat === 'exponential') {
                                temperatureValue = Math.exp(temperatureValue);
                            }
                            
                            // Handle valid values
                            if (temperatureValue !== null && isFinite(temperatureValue)) {
                                temperatures.push(temperatureValue); // Collect y-values
                            }
                        }
                    });
                });

                datasets.push({
                    label: batchLabel,
                    data: temperatures,
                    fill: false,
                    borderColor: batch.color,
                    borderWidth: batch.lineWidth,
                    pointBackgroundColor: 'white',
                    pointRadius: 3,
                    pointHoverRadius: 3,
                    tension: 0.1,
                });
            }
        });

        return { labels: Array.from(labelSet), datasets };
    };

    const { labels, datasets } = prepareChartData(data);

    const chartData = {
        labels,
        datasets,
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Seconds',
                },
                type: 'category', // Ensure this is set
                ticks: {
                    autoSkip: false, // Disable auto-skip to check visibility
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Temperature',
                },
                type: yAxisFormat === 'logarithmic' ? 'logarithmic' : 'linear',
                min: 0,
                max: (maxValue || Math.max(...datasets.flatMap(ds => ds.data))) + 40,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
                    },
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                }, zoom: { wheel: {
                    enabled: true, // Enable zooming with mouse wheel
                },
                mode: 'xy', // Allow zooming in both axes
                onZoom: ({ chart }) => {
                    const xAxis = chart.scales.x;
                    const yAxis = chart.scales.y;

                    // Ensure X-axis stays within valid range
                    const minLabelIndex = Math.max(0, xAxis.min);
                    const maxLabelIndex = Math.min(labels.length - 1, xAxis.max);
                    xAxis.min = labels[minLabelIndex];
                    xAxis.max = labels[maxLabelIndex];
                }
            },
        },
    },
    legend: {
        display: false, // Hide the legend
    },
};

// Update min and max values dynamically
const yAxisValues = datasets.flatMap(d => d.data).filter(value => isFinite(value));
setMinValue(Math.min(...yAxisValues));
setMaxValue(Math.max(...yAxisValues));

return (
    <div className='w-full h-[60vh]'>
        <Line data={chartData} options={options} />
    </div>
);
};

export default FurnaceChart;