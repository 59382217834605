import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'antd';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import './index.css'; // Ensure your custom styles are applied
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
export default function DateRangePickerComponent({ dateRange, setDateRange, label = "Pick a date range" }) {
    const [startDate, setStartDate] = useState(dateRange[0] ? dayjs(dateRange[0]).toDate() : null);
    const [endDate, setEndDate] = useState(dateRange[1] ? dayjs(dateRange[1]).toDate() : null)
    const [isPickerOpen, setIsPickerOpen] = useState(false); // Control visibility of the DatePicker

    const datePickerRef = useRef(null); // Ref for the container of the date picker

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
            setDateRange([dayjs(dates[0]).format('YYYY-DD-MM'), dayjs(dates[1]).format('YYYY-DD-MM')]);
        }
    };

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setDateRange([null, null]); // Clear the range
    };

    const handleInputClick = () => {
        setIsPickerOpen(!isPickerOpen); // Toggle the picker visibility
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsPickerOpen(false); // Close the picker if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup listener on unmount
        };
    }, [datePickerRef]);

    const formattedStartDate = startDate ? dayjs(startDate).format('MMM D, YYYY') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('MMM D, YYYY') : '';
    return (
        <div style={{ width: '300px', position: 'relative' }} ref={datePickerRef}>
            {isPickerOpen && (
                <div className="z-20" style={{ position: 'absolute', top: '40px', left: '0',  padding: '10px', borderRadius: '5px',  width:"180%" }}>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        monthsShown={2}
                    />
                </div>
            )}

            {/* Text box to show selected dates */}
            <div
                onClick={handleInputClick}
                style={{
                    padding: '8px 8px',
                    border: '1px solid #d4d4d4',
                    borderRadius: '6px',
                    background: 'white',
                    color: '#979797',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: 300,
                    textAlign: 'left',
                    height: 40,
                }}
            >
                <span className='pt-1 text-sm leading-5 flex gap-x-2 items-center justify-center pl-2 h-full'>
                    <CalendarTodayIcon className='!w-4 !h-4' style={{ color: "#171717" }} />
                    {formattedStartDate && formattedEndDate
                        ? `${formattedStartDate} - ${formattedEndDate}`
                        : label}
                </span>
                {startDate && endDate && (
                    <Button
                        onClick={handleClear}
                        size="small"
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: 12,
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: 0,
                            color: '#979797'
                        }}
                        icon={<i className="fas fa-times" style={{ fontSize: 16 }} />}
                    />
                )}
            </div>
        </div>
    );
}