import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../routes/path'
function LoginForm() {
  const { auth, login } = useContext(AuthContext)
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .matches(
            /^[A-Za-z0-9][A-Za-z0-9 ]{0,74}$/,
            "Special characters not allowed"
          )
          .max(75, "Maximum length allowed is 75")
          .min(1, "Minimum length allowed is 1")
          .test(
            "no-starting-space",
            "Username cannot start with a space",
            (value) => value && !value.startsWith(" ")
          )
          .required("Username is required"),
        password: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
            " "
          )
          .max(32, "Maximum length allowed is 32")
          .min(8, "Minimum length allowed is 8")
          .required("Password is required"),
      })}
      onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
        setSubmitting(true);
        setStatus();
        login(username, password)
          .then((res) => {
            if (res?.status === 200) {
              navigate(PATHS.homePath);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              setStatus(error.response.data.message);
            } else {
              setStatus("Invalid username or password. Please try again.");
            }
            setSubmitting(false);
            setTimeout(() => setStatus(null), 8000);
          });

      }}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-textHeading"
            >
              Username
            </label>
            <Field
              className="form-input mt-1 block w-full !h-[44px] bg-white rounded-md border border-gray-300 shadow-sm pl-2 focus:outline-none"
              as="input"
              name="username"
              type="text"
              placeholder=" Enter your username"
            />
            <ErrorMessage
              name="username"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <Field
              className="form-input mt-1 block w-full !h-[44px] rounded-md border bg-white border-gray-300 shadow-sm pl-2 focus:outline-none"
              as="input"
              name="password"
              type="password"
              placeholder=" Enter your password"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-600 text-sm"
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-orange-500 font-semibold text-white rounded-md transition duration-200"
            disabled={isSubmitting}
          >
            Login
          </button>

          {status && (
            <div className="mt-2 text-red-600 text-sm p-2">
              {status}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
