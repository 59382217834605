import React, { useEffect, useState } from 'react'
import getAPIMap from '../../routes/url/ApiUrls'
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate'

const EditPens = ({ penId, setSearchParams, setShowModal, setModalContext }) => {
    const axiosPrivate = useAxiosPrivate()


    const closeModal = () => {
        setSearchParams({ action: "view" })
    }
    const handleSubmit = async () => {
        try {
            const response = await axiosPrivate.delete(`${getAPIMap("penlist")}/${penId}`)
            console.log(response)
            if (response.status === 200) {
                setShowModal(true)
                setModalContext({
                    text: "Pen details deleted successfully. Check the pen list.",
                    title: "",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='w-full h-screen flex justify-center items-center bg-opacity-70 bg-backgroundColor'>
            <div className='w-4/12 bg-white rounded-md shadow-lg'>
                <div className='flex justify-between items-center px-8 pt-6 gap-x-4'>
                    <h1 className='text-textHeading font-semibold text-[16px]/[28px]'>Are you sure to delete this pen?</h1>
                    <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">
                        &times;
                    </button>
                </div>
                <div className='text-[12px]/[20px] w-11/12 px-8 text-textGray mb-4'>
                    This action cannot be undone. This will  delete the pen  remove it from penlist.                </div>

                <div className="px-8 py-4 gap-x-4 flex justify-end">
                    <button className="px-4 py-2 border border-borderColorBox bg-white text-[#0F172A] hover:outline-none rounded-md" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className={`px-4 py-2 bg-baseOrange text-white rounded-md font-medium transition duration-300 `}
                        onClick={handleSubmit}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditPens
