import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker'; // Import DatePicker from react-datepicker
import { Button } from '@mui/material'; // Use MUI Button
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import dayjs from 'dayjs';
import { Chip, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CustomSearchableSelect from '../SearchableSelect/index'; // Import your searchable select component
import '../DateSelecter/index.css'
const DateRangePickerComponent = React.memo(({ label = "Pick a date range", options = [], batchList,
    batchOptionSelected,
    handleDateRender,
    sethandleDateRender,
    setBatchOptionsSelected,
    handleOptionChange, dateRange, setDateRange }) => {

    const [isSelectOpen, setIsSelectOpen] = useState(false); // Control visibility of the searchable select
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]); // State for selected options
    const [startDate, setStartDate] = useState(null); // Start date state
    const [endDate, setEndDate] = useState(null); // End date state
    const datePickerRef = useRef(null); // Ref for the date picker container
    const [listTempSelected, setListTempSelect] = useState([])
    const handleCalendar = useRef(null)

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
            setDateRange([dayjs(dates[0]).format('YYYY-MM-DD'), dayjs(dates[1]).format('YYYY-MM-DD')]);
        }
    };

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setDateRange([null, null]); // Clear the range
    };

    const handleSelectClick = () => {
        setIsSelectOpen(!isSelectOpen); // Toggle the select dropdown visibility
    };
    const handleCalendarClick = () => {
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the select dropdown visibility
    };

    useEffect(() => {
        if (handleDateRender) {
            setStartDate(null)
            setEndDate(null)
            sethandleDateRender(false)
        }
    }, [handleDateRender])


    const handleToggleDatePicker = () => {
        setIsSelectOpen(false); // Close the dropdown when opening the date picker
        setStartDate(null); // Optionally reset start and end dates when dropdown is opened
        setEndDate(null);
    };

    const formattedStartDate = startDate ? dayjs(startDate).format('MMM D, YYYY') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('MMM D, YYYY') : '';
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsDatePickerOpen(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside); // Add the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup the listener
        };
    }, [datePickerRef]);
    const handleAdd = () => {
        if (batchOptionSelected.length > 0) {
            setListTempSelect([...batchOptionSelected])
        }
    }
    const handleRemoveChip = (value) => {
        setListTempSelect((prevList) => prevList.filter((batch) => batch !== value));
        setBatchOptionsSelected((prev)=>prev.filter((batch) => batch !== value))
    };
    console.log(listTempSelected, batchOptionSelected)
    useEffect(() => {

    }, [listTempSelected])
    return (
        <div style={{ position: 'relative' }} ref={datePickerRef}>
            <div
                onClick={handleSelectClick}
                style={{
                    width: '300px',
                    padding: '8px',
                    border: '1px solid #d4d4d4',
                    borderRadius: '6px',
                    background: 'white',
                    color: '#979797',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: 40,
                }}
            >
                <span className="pt-1 text-sm leading-5 flex gap-x-2 items-center justify-center pl-2">
                    {listTempSelected.length > 0
                        ? listTempSelected?.map((batch) => {
                            return (
                                <div className="flex gap-y-2" key={batch}>
                                    <Chip
                                        key={batch}
                                        deleteIcon={<CloseIcon className="!w-4" />}
                                        label={batch}
                                        onDelete={() => handleRemoveChip(batch)}
                                        style={{ margin: '2px', backgroundColor: '#F5F5F5', color: '#333' }}
                                    />
                                </div>
                            );
                        })
                        : <span className='text-[12px]/[24px]'> {label} </span>}
                </span>
                {startDate && endDate && (
                    <Button
                        onClick={handleClear}
                        size="small"
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: 12,
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: 0,
                            color: '#979797',
                        }}
                    >
                        <CloseIcon style={{ fontSize: 16 }} />
                    </Button>
                )}
            </div>

            {isSelectOpen && (
                <div
                    className="z-50 mt-3 border border-customGray rounded-md bg-white shadow-md"
                    style={{
                        position: 'absolute', // Position as overlay
                        top: '100%',          // Position directly below the parent
                        left: 0,              // Align with the left side of the parent container
                        width: '230%',        // Take full width of the parent
                        zIndex: 1000,         // Ensure it's on top of other elements
                    }}
                >
                    <div className="w-full flex justify-center items-stretch h-full p-3">
                        <div className="p-2 flex-1">
                            <h3 className="text-black font-medium text-[16px]/[20px]">Add batch</h3>
                            <p className="pt-1 text-textGray text-[12px]/[20px]">You can select only 10 batches</p>
                            <p className="pt-3 text-[#171717] text-[14px]/[20px] pb-2">Time period</p>

                            <div
                                onClick={handleCalendarClick} // Toggle calendar
                                style={{
                                    padding: '8px',
                                    border: '1px solid #d4d4d4',
                                    borderRadius: '6px',
                                    background: 'white',
                                    color: '#979797',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    height: 40,
                                    position: 'relative', // Allow button positioning relative to this div
                                }}
                            >
                                <span className="text-[12px]/[24px] leading-5 flex gap-x-2 items-center justify-center pl-2">
                                    <CalendarTodayIcon className="!w-4 !h-4" style={{ color: '#171717' }} />
                                    {formattedStartDate && formattedEndDate
                                        ? `${formattedStartDate} - ${formattedEndDate}`
                                        : label}
                                </span>
                                {startDate && endDate && (
                                    <Button
                                        onClick={handleClear}
                                        size="small"
                                        style={{
                                            position: 'absolute',
                                            right: 8,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: 12,
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            padding: 0,
                                            color: '#979797',
                                        }}
                                    >
                                        <CloseIcon style={{ fontSize: 16 }} />
                                    </Button>
                                )}
                            </div>

                            {isDatePickerOpen && (
                                <div style={{ position: 'absolute', zIndex: 1010, marginTop: '10px' }} ref={datePickerRef}>
                                    <DatePicker
                                        selectsEnd
                                        selected={startDate}
                                        showTwoColumnMonthYearPicker={true}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        monthsShown={2}
                                        selectsRange
                                        inline
                                    />
                                </div>
                            )}

                            {/* Custom Searchable Select */}
                            <div style={{ position: 'relative', zIndex: 1000, marginTop: '10px' }}>
                                <CustomSearchableSelect
                                    options={batchList}
                                    selectedOptions={batchOptionSelected}
                                    onChange={handleOptionChange}
                                    multiSelect={true}
                                    placeholder="Select batch from the drop down"
                                    selectMaxCount={10}
                                    showParameter={false}
                                />
                            </div>
                            <div className="mt-3 w-full flex justify-end">
                                <button className="border border-baseOrange w-24 text-baseOrange h-9 flex justify-center items-center rounded-md" onClick={handleAdd}>+ Add</button>
                            </div>
                        </div>
                        <div className="w-[1px] bg-[#D4D4D4] my-3"></div>
                        <div className="w-80 flex-1">
                            <div className="w-full h-10 flex justify-end p-4" onClick={handleSelectClick}>
                                <CloseIcon />
                            </div>
                            <div className="p-4 h-full">
                            {batchOptionSelected.length > 0 ? (
    <div className="w-full h-[65.5%] rounded-md p-3 border border-[#D4D4D4] text-customGray text-[12px]/[20px]">
        <div
            className="flex flex-wrap gap-2 overflow-auto"
            style={{
                maxHeight: '100%', // Ensures it doesn't exceed container height
                overflowY: 'auto', // Enables vertical scrolling if necessary
            }}
        >
            {listTempSelected?.map((batch, index) => (
                <div
                    key={index}
                    className="text-customGray text-[12px]/[20px] m-1 px-2 bg-[#F5F5F5] truncate"
                    style={{
                        maxWidth: '100%', // Ensures it doesn't overflow horizontally
                        whiteSpace: 'nowrap', // Prevents wrapping of the batch text
                        textOverflow: 'ellipsis', // Adds ellipsis for long text
                        overflow: 'hidden', // Hides any overflowed content
                    }}
                >
                    {batch}
                </div>
            ))}
        </div>
    </div>
) : (
    <div className="w-full h-[65.5%] rounded-md p-3 border border-[#D4D4D4] text-customGray text-[12px]/[20px]">
        Start selecting batches to compare
    </div>
)}

                                <div className="mt-3 w-full flex justify-end">
                                    <button
                                        className="border border-baseOrange w-24 bg-baseOrange text-white h-9 flex justify-center items-center rounded-md"
                                        onClick={handleSelectClick}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

})

export default DateRangePickerComponent;