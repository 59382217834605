import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Chip, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const CustomSearchableSelect = ({
    options = [],
    selectedOptions = [],
    onChange,
    showLabel = false,
    placeholder = 'Select Options',
    multiSelect = false,
    selectMaxCount,
    showParameter = true,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const filteredOptions = useMemo(() => {
        return options.filter((option) =>
            option.label.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, options]);

    const handleRemoveChip = (value) => {
        onChange(selectedOptions.filter((option) => option !== value));
    };

    const toggleOption = (value) => {
        if (multiSelect) {
            if (selectedOptions.includes(value)) {
                onChange(selectedOptions.filter((option) => option !== value));
            } else if (!selectMaxCount || selectedOptions.length < selectMaxCount) {
                onChange([...selectedOptions, value]);
            }
        } else {
            onChange(selectedOptions.includes(value) ? [] : [value]);
            setIsOpen(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            {showLabel && <label className="text-sm font-semibold mb-2 block">Select Options</label>}
            <div style={{ position: 'relative', maxWidth: '330px', minWidth: '330px' }}>
                <div
                    style={{
                        border: '1px solid #EB7E39',
                        borderRadius: '4px',
                        display: 'flex',
                        flexWrap: 'nowrap', // Prevent chips from wrapping to next line
                        minHeight: '40px',
                        maxHeight: '40px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#FFFFFF',
                        padding: '1px',
                        overflow: 'hidden', // Prevent overflowing of chips
                        whiteSpace: 'nowrap', // Prevent chip labels from breaking
                    }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: '100%', // Prevent chip container from expanding
                            paddingRight: '8px',
                            alignItems: 'center',
                        }}
                    >
                        {selectedOptions.length > 0 ? (
                            selectedOptions.map((option) => {
                                const matchedOption = options.find((opt) => opt.value === option);
                                return (
                                    matchedOption && (
                                        <Chip
                                            key={option}
                                            deleteIcon={<CloseIcon className="!w-4" />}
                                            label={
                                                <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                    <span className="text-sm font-semibold">
                                                        {matchedOption.label}
                                                    </span>
                                                    {showParameter && (
                                                        <span className="text-xs text-gray-500">
                                                            {matchedOption.penIds?.length} parameter
                                                        </span>
                                                    )}
                                                </div>
                                            }
                                            onDelete={() => handleRemoveChip(option)}
                                            style={{
                                                margin: '2px',
                                                backgroundColor: '#F5F5F5',
                                                color: '#333',
                                                maxWidth: '150px', // Limit chip width
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis', // Ellipsis for long labels
                                            }}
                                        />
                                    )
                                );
                            })
                        ) : (
                            <span className="pl-2 text-[12px]/[24px] text-[#979797]">{placeholder}</span>
                        )}
                    </div>
                    <ExpandMoreIcon style={{ marginLeft: 'auto', color: '#979797' }} />
                </div>

                {isOpen && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '120%',
                            left: 0,
                            width: '100%',
                            border: '1px solid #EB7E39',
                            borderRadius: '8px',
                            backgroundColor: '#FFFFFF',
                            zIndex: 1000,
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{
                                    flexGrow: 1,
                                    padding: '8px',
                                    border: '1px solid #979797',
                                    borderRadius: '4px',
                                }}
                            />
                            <SearchIcon style={{ marginLeft: '8px', color: '#979797' }} />
                        </div>
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => (
                                    <div
                                        key={option.value}
                                        onClick={() => toggleOption(option.value)}
                                        style={{
                                            padding: '5px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <span>{option.label}</span>
                                        {multiSelect && (
                                            <Checkbox
                                                checked={selectedOptions.includes(option.value)}
                                                onChange={() => toggleOption(option.value)}
                                                sx={{
                                                    color: '#EB7E39',
                                                    '& .MuiSvgIcon-root': {
                                                        color: selectedOptions.includes(option.value)
                                                            ? '#EB7E39'
                                                            : 'default',
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div style={{ padding: '10px', color: '#999' }}>No options found</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSearchableSelect;
