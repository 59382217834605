import React, { useState } from "react";
import BatchDetails from "./BatchDetails";
import BatchComparison from "./BatchComparison";

const tabs = [
  {
    key: "batchDetails",
    label: "Batch Details",
    component: BatchDetails,
  },
  {
    key: "batchComparison",
    label: "Batch Comparison",
    component: BatchComparison,
  },
];

const Home = () => {
  const [selectedOption, setSelectedOption] = useState("batchDetails");

  const handleTabChange = (key) => {
    setSelectedOption(key);
  };

  const renderActiveComponent = () => {
    const activeTab = tabs.find((tab) => tab.key === selectedOption);
    return activeTab?.component ? <activeTab.component /> : null; // Render as JSX
  };

  return (
    <div className="max-h-screen flex flex-col">
      <div className="w-4/12 h-10 flex justify-center items-center">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`${selectedOption === tab.key
              ? "bg-white rounded-t-md text-textGray font-semibold text-[14px]/[20px] border"
              : "text-white font-semibold text-[14px]/[20px]"
              } cursor-pointer w-1/2 h-full flex justify-center items-center`}
            onClick={() => handleTabChange(tab.key)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      <div className="h-[80vh] mb-4">
        {renderActiveComponent()}
      </div>
    </div>
  );
};

export default Home;