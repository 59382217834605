import React from 'react';
import { NoFilterSvg } from '../../assets/svgs';

const Index = ({ label }) => {
    return (
        <div className="w-full h-[79vh] rounded-b-md  rounded-r-md mb-4 bg-white">
            <div className='flex justify-center items-center w-full h-full'>
                <div className='flex flex-col justify-center items-center text-center'>
                    <div className='text-customGray font-semibold pb-4 text-[32px]/[48px]'>{label}</div>
                    <NoFilterSvg />
                </div>
            </div>
        </div>
    );
};

export default Index;
