export const PATHS = {
    login: "/sign-in",
    role: "/role",
    addRole: "/role/add-role",
    user: "/dashboard",
    homePath: '/',
    addUser: "/user/add-user",
    userUpload: "/user/upload",
    dbConnection: "/db",
    penlist: `/pen`
};
