import React, { useEffect, useState,useCallback } from 'react';
import NoFiltersScreen from '../components/NoFiltersScreen/index';
import SearchandSelectBatch from '../components/SearchandSelectBatch';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getAPIMap from '../routes/url/ApiUrls';
import SidebarMenu from '../components/LineChart/Sidebar';
import CustomMultiLineChart from '../components/LineChart/index';

const colorCodes = [
    "#EB7E39", "#486C3D", "#1977F1", "#8F2B83", "#23487C", 
    "#58AB40", "#49C2B6", "#BE5153", "#4A5C73"
];

// Get random color
const getRandomDarkColor = (usedColors) => {
    let color;
    do {
        const r = Math.floor(Math.random() * 128); // Red value
        const g = Math.floor(Math.random() * 128); // Green value
        const b = Math.floor(Math.random() * 128); // Blue value
        color = `rgb(${r}, ${g}, ${b})`;
    } while (usedColors?.has(color)); // Ensure it's unique
    return color;
};

// Dummy data
const dummyData = [
    {
        penName: 'Pen 1',
        penResult: [
            { DATE_TIME: '2024-01-01T00:00:00.000Z', value1: 65.23, value2: 50.12 },
            { DATE_TIME: '2024-01-01T00:10:00.000Z', value1: 70.45, value2: 53.67 },
            // Add more data points...
        ]
    },
    {
        penName: 'Pen 2',
        penResult: [
            { DATE_TIME: '2024-01-01T00:00:00.000Z', value1: 54.23, value2: 89.12 },
            { DATE_TIME: '2024-01-01T00:10:00.000Z', value1: 58.67, value2: 92.34 },
            // Add more data points...
        ]
    },
    // More pens...
];

const BatchDetails = () => {
    const axiosPrivate = useAxiosPrivate();

    // States
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [data, setData] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [visibleDatasets, setVisibleDatasets] = useState([]);
    const [yAxisVisibility, setYAxisVisibility] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [multiSelect, setMultiSelect] = useState(false);
    const [colors, setColors] = useState([]);
    const [minValues, setMinValues] = useState([]);
    const [maxValues, setMaxValues] = useState([]);
    const [lineWidths, setLineWidths] = useState([]);
    const [penColorMap, setPenColorMap] = useState({});
    const [selectedFormat, setSelectedFormat] = useState("exponent");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptions2, setSelectedOptions2] = useState([]);
    const [yAxisDirection, setYAxisDirection] = useState([]);
    const [groupData, setGroupData] = useState([]);

    const generateColorMapping = useCallback((data) => {
        let availableColors = [...colorCodes];
        const mapping = { ...penColorMap };
    
        data.forEach(group => {
            if (!mapping[group.penName]) {
                if (availableColors.length === 0) {
                    console.warn("No more available colors to assign.");
                    return;
                }
                const randomIndex = Math.floor(Math.random() * availableColors.length);
                const selectedColor = availableColors.splice(randomIndex, 1)[0];
                mapping[group.penName] = selectedColor;
            }
        });
    
        setPenColorMap(mapping);
    }, [penColorMap]); 
    const fetchBatchList = async () => {
        try {
            const response = await axiosPrivate.get(getAPIMap("listBatches"));
            const batches = response?.data?.data?.map(batch => ({
                label: batch.id,
                value: batch.id
            })) || [];
            setBatchList(batches);
        } catch (error) {
            console.error("Error fetching batch list:", error);
        }
    };

    const fetchGroupList = async () => {
        try {
            const response = await axiosPrivate.get(getAPIMap("groupByUserType"));
            console.log(response.data)
            const groups = response?.data?.map(group => ({
                label: group.name,
                value: group.id,
                penIds: group.penList
            })) || [];
            console.log(groups)
            setGroupList(groups);
        } catch (error) {
            console.error("Error fetching group list:", error);
        }
    };

    // Initialize component
    useEffect(() => {
        fetchGroupList();
        fetchBatchList();
    }, []);

    useEffect(() => {
        if (groupList.length > 0) {
            initializeColors();
            setVisibleDatasets(new Array(groupList.length).fill(true));
            setYAxisVisibility(new Array(groupList.length).fill(true));
            setYAxisDirection(new Array(groupList.length).fill("left"));
            setSelectedFormats(new Array(groupList.length).fill("linear"));
        }
    }, [groupList]);  
    const initializeColors = () => {
        if (!groupList || groupList.length === 0) return;

        const usedColors = new Set();
        const generatedColors = groupList.map(() => {
            const newColor = getRandomDarkColor(usedColors);
            usedColors.add(newColor);
            return newColor;
        });

        setColors(generatedColors);
        setMinValues(new Array(generatedColors.length).fill(0));
        setMaxValues(new Array(generatedColors.length).fill(100));
        setLineWidths(new Array(generatedColors.length).fill(2));

        const newPenColorMap = groupList.reduce((map, group, index) => {
            map[group.value] = generatedColors[index];
            return map;
        }, {});
        setPenColorMap(newPenColorMap);
    };

    // Handle dataset toggling
    const handleToggleDataset = (index) => {
        const updatedVisibility = [...visibleDatasets];
        updatedVisibility[index] = !updatedVisibility[index];
        setVisibleDatasets(updatedVisibility);
    };

    // Fetch data
    const handleFetchData = async () => {
        try {
            const penId = selectedOptions2.flatMap(groupValue => {
                const matchingGroup = groupList.find(group => group.value === groupValue);
                return matchingGroup 
                    ? matchingGroup.penIds.filter(pen => pen.pen_id !== null).map(pen => pen.pen_id)
                    : [];
            });
    console.log(selectedOptions2)
            // Prepare the request body
            const requestBody = {
                penIds: penId,
                groupId:selectedOptions2[0],
                startDateTime: dateRange[0],
                endDateTime: dateRange[1],
                batchNumber: selectedOptions[0] || "63"
            };
    
            // Make the API call
            const response = await axiosPrivate.post(getAPIMap("getBatchDetails"), requestBody);
    
            // Process the response
            const fetchedData = response?.data?.data || [];
    
            // Prepare new min and max values based on the fetched data
            const newMinValues = [];
            const newMaxValues = [];
            
            fetchedData.forEach(pen => {
                const values = pen.penResult.map(result => result.value1); // Use 'value1' or 'value2' as needed
                if (values && values.length > 0) {
                    newMinValues.push(Math.min(...values));
                    newMaxValues.push(Math.max(...values));
                } else {
                    newMinValues.push(0); // Default to 0 if no data
                    newMaxValues.push(100); // Default max value
                }
            });
    
            // Update the states after calculating min/max
            generateColorMapping(fetchedData);
            setGroupData(fetchedData);
            setData(fetchedData);
            setMinValues(newMinValues);
            setMaxValues(newMaxValues);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    // Handle color change for a dataset
    const handleChangeColor = (index, color) => {
        const updatedPenColorMap = { ...penColorMap };
        updatedPenColorMap[data[index].penName] = color;
        setPenColorMap(updatedPenColorMap);
    };

    // Handle toggling Y-Axis visibility for a dataset
    const handleToggleYAxisVisibility = (index) => {
        const updatedVisibility = [...yAxisVisibility];
        updatedVisibility[index] = !updatedVisibility[index];
        setYAxisVisibility(updatedVisibility);
    };

    // Handle change in min/max values for datasets
    const handleChangeMinMax = (index, type, value) => {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) return; // Ignore if value is not a number

        const updatedValues = type === 'min' ? [...minValues] : [...maxValues];
        updatedValues[index] = parsedValue;

        type === 'min' ? setMinValues(updatedValues) : setMaxValues(updatedValues);
    };

    // Handle change in line width for a dataset
    const handleChangeLineWidth = (index, value) => {
        const updatedWidths = [...lineWidths];
        updatedWidths[index] = parseInt(value, 10);
        setLineWidths(updatedWidths);
    };

    const handleChangeYAxisDirection = (newDirection, index) => {
        console.log(newDirection)
        // Create a copy of the current Y-axis direction array
        const updatedDirections = [...yAxisDirection];
        // Update the specific Y-axis direction at the given index
        updatedDirections[index] = newDirection;
        // Update the state with the new array
        setYAxisDirection(updatedDirections);
    };

    const handleChangeFormat = (index, format) => {
        const updatedFormats = [...selectedFormats];
        updatedFormats[index] = format; // Update the specific dataset's format
        setSelectedFormats(updatedFormats);
    };
    


    return (
        <div className="bg-white w-full mb-40 rounded-r-md rounded-bl-md rounded-l-none">
            <SearchandSelectBatch
                dateRange={dateRange}
                groupList={groupList}
                setDateRange={setDateRange}
                dummyData={dummyData}
                batchList={batchList}
                colors={colors}
                groupData={groupData}
                setGroupData={setGroupData}
                setData={setData}
                generateColorMapping={generateColorMapping}
                penColorMap={penColorMap}
                setPenColorMap={setPenColorMap}
                visibleDatasets={visibleDatasets}
                setVisibleDatasets={setVisibleDatasets}
                selectedOptions={selectedOptions}
                handleFetchData={handleFetchData}
                selectedOptions2={selectedOptions2}
                setSelectedOptions={setSelectedOptions}
                setSelectedOptions2={setSelectedOptions2}
            />
            <div className="mt-4 flex flex-row">
                {data.length === 0 ? (
                    <NoFiltersScreen label={'Add filters to generate graph!'} />
                ) : (
                    <div className='flex flex-row-reverse w-full'>
                        <div className="sidebar-container w-1/4 p-4">
                            <SidebarMenu
                                data={data}
                                setMinValues={setMinValues}
                                setMaxValues={setMaxValues}
                                visibleDatasets={visibleDatasets}
                                handleToggleDataset={handleToggleDataset}
                                colors={colors}
                                handleChangeColor={handleChangeColor}
                                minValues={minValues}
                                getRandomColor={getRandomDarkColor}
                                maxValues={maxValues}
                                penColorMap={penColorMap}
                                handleChangeMinMax={handleChangeMinMax}
                                lineWidths={lineWidths}
                                handleChangeLineWidth={handleChangeLineWidth}
                                selectedFormats={selectedFormats}
                                handleToggleScaleVisible={handleToggleYAxisVisibility}
                                handleChangeFormat={handleChangeFormat}
                                yAxisVisibility={yAxisVisibility}
                                yAxisDirection={yAxisDirection}
                                handleChangeYAxisDirection={handleChangeYAxisDirection}
                            />
                        </div>

                        <div className="chart-container flex-1 p-4 pl-8 mb-4" style={{ maxWidth: '100%', maxHeight: '800px' }}>
                            <CustomMultiLineChart
                                visibleDatasets={visibleDatasets}
                                colors={colors}
                                minValues={minValues}
                                maxValues={maxValues}
                                lineWidths={lineWidths}
                                getRandomColor={getRandomDarkColor}
                                data={data}
                                setData={setData}
                                selectedFormats={selectedFormats}
                                penColorMap={penColorMap}
                                yAxisVisibility={yAxisVisibility}
                                yAxisDirection={yAxisDirection}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BatchDetails;
