import React from 'react';
import { Switch } from 'antd';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './index.css'
const Index = ({ data, toggleVisibility, handleLineWidthChange,handleColorChange,isOpen,OpenSubMenu,index }) => {
    console.log(`Data inside the sidebar is ${index}`);
    console.log(data);

    return (
        <div className='w-full'>
            <div className='w-full'>
                <div className="px-1 mt-3 flex justify-between items-center">
                    <div className="flex gap-x-2 items-center">
                        <div style={{ backgroundColor: `${data.color}` }} className="!w-4 !h-4 rounded-full"></div>
                        <div className='text-[12px]/[14px]  text-[#1E1B39] font-medium'>{data.batchNumber}</div>
                    </div>
                    <div className="flex gap-x-2 justify-center items-center">
                        <Switch
                            checked={data.visibility} 
                         size='small'
                            onChange={()=>{toggleVisibility(index)}} // Handle toggle event
                            checkedChildren={<span style={{ backgroundColor: data.color }}></span>} // Show "On" when checked
                            style={{

                                backgroundColor: data.visibility ? data.color : undefined, // Conditional background color
                            }}
                        />

<div onClick={OpenSubMenu}>{isOpen?<KeyboardArrowUpIcon/>:<ChevronRightIcon/>}</div>
                    </div>

                </div>

                {isOpen && (
                    <div className="">
        <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="minValue" className="text-[12px]/[20px] text-[#555555]">Fill Color</label>
            <input 
    id="color" 
    type="color" 
    value={data.color}
    onChange={(e)=>{handleColorChange(index,e)}}
    className="border rounded-sm  focus:outline-none" 
    placeholder="Enter minimum value"
/>

        </div>
        <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="minValue" className="text-[12px]/[20px] text-[#555555]">Line Size</label>
            <input 
    id="text" 
 
    value={`${data.lineWidth}`}
    onChange={(e)=>{handleLineWidthChange(index,e)}}
    className="border rounded-sm w-3/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] focus:outline:none" 
    placeholder="Enter minimum value"
/>

        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;