import React from 'react';
import PropTypes from 'prop-types';

const CustomModal = ({ showModal, modalContext, icon, closeModal, navigate }) => {
    return (
        <>
            {showModal ? (
                <div className="fixed inset-0 flex items-center justify-center bg-opacity-70 bg-backgroundColor z-40">
                    <div className="w-[30vw] px-4 py-2 bg-white rounded-md shadow-lg">
                        <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center">
                                <div className="mr-2">{icon}</div> {/* Icon */}
                            </div>
                            <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">
                                &times;
                            </button>
                        </div>
                        <div className="flex justify-center mb-4">
                            <div className="text-center">
                                <p className=" text-customGray">{modalContext.text}</p>
                            </div>
                        </div>
                        <div className="flex justify-end mb-2">
                            <button
                                onClick={navigate}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300">
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

CustomModal.propTypes = {
    modalContext: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }).isRequired,
    icon: PropTypes.element.isRequired,
    closeModal: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default CustomModal;
