import React, { useEffect, useState } from 'react';
import DateRangePickerComponent from '../DateSelecter'; // Adjust the import path as needed
import SearchableSelect from '../SearchableSelect'; // Adjust the import path as needed

const BatchFilter = ({
    setData,
    dummyData,
    dateRange,
    batchList,
    colors,
    groupList,
    handleFetchData,
    penColorMap,
    setPenColorMap,
    setDateRange,
    multiSelect,
    groupData,
    setGroupData,
    generateColorMapping,
    visibleDatasets,
    setVisibleDatasets,
    selectedOptions,
    selectedOptions2,
    setSelectedOptions,
    setSelectedOptions2,
}) => {
    const handleOptionChange = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
    };

    const handleOptionChange2 = (newSelectedOptions) => {
        setSelectedOptions2(newSelectedOptions);
    };

    const toggleVisibility = (index) => {
        const updatedVisibility = [...visibleDatasets];
        updatedVisibility[index] = !updatedVisibility[index]; // Toggle the visibility
        setVisibleDatasets(updatedVisibility);
    };

    useEffect(() => {
        // Use JSON.stringify to compare objects if necessary
        const penColorMapHash = JSON.stringify(penColorMap);
        generateColorMapping(groupData); 
    }, [JSON.stringify(groupData)]); // Avoid penColorMap as dependency

    return (
        <div className="pt-3">
            <div
                className={`shadow-lg ${groupData.length === 0 ? 'h-16' : 'h-28'} pt-3 flex-col items-center mx-4 w-[98%] rounded-md bg-bgBatchDetailsFilter`}
            >
                <div className="w-full ml-2 flex gap-x-1 justify-start items-center">
                    <DateRangePickerComponent dateRange={dateRange} setDateRange={setDateRange} />

                    <div className="max-w-4/12">
                        <SearchableSelect
                            options={batchList}
                            showLabel={false}
                            showSearch={true}
                            singleSelect={false}
                            multiSelect={multiSelect}
                            showAddOption={true}
                            showParameter={false}
                            placeholder="Select batch from the drop down"
                            selectedOptions={selectedOptions}
                            onChange={handleOptionChange}
                        />
                    </div>

                    <div className="max-w-4/12">
                        <SearchableSelect
                            options={groupList}
                            showLabel={false}
                            showSearch={true}
                            showParameter={false}
                            multiSelect={multiSelect}
                            singleSelect={false}
                            showAddOption={true}
                            placeholder="Select group from the drop down"
                            selectedOptions={selectedOptions2}
                            onChange={handleOptionChange2}
                        />
                    </div>

                    <div
                        className="w-3/12 mr-2 rounded-md bg-baseOrange h-10 text-white flex justify-center items-center cursor-pointer"
                        onClick={handleFetchData}
                    >
                        Apply Filters
                    </div>
                </div>

                <div className="flex gap-x-2 !z-50">
                    {groupData.length > 0 ? (
                        groupData?.map((group, index) => {
                            const backgroundColor = penColorMap[group.penName] || '#000'; // Fallback if color is not set
                            return (
                                <div
                                    key={group.id}
                                    style={{
                                        backgroundColor: visibleDatasets[index]
                                            ? backgroundColor
                                            : '#979797', // Apply color correctly
                                    }}
                                    className={`p-2 text-white rounded-full text-[12px] mt-2 cursor-pointer`}
                                    onClick={() => toggleVisibility(index)}
                                >
                                    <p>{group.penName}</p>
                                </div>
                            );
                        })
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default BatchFilter;
