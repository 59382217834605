import DBConnection from "../../pages/DBConnection";
import Login from "../../pages/Login";
import { PATHS } from "../path";

export const publicMapping = [{
    path: PATHS.login,
    component: Login,
    name: "Login Page",
    exact: true,
},
{
    path: PATHS.dbConnection,
    component: DBConnection,
    name: "DB Connection",
    exact: true,
}
]