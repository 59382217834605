import React, { useEffect, useState } from 'react';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import getAPIMap from '../../routes/url/ApiUrls';
import { DeleteIcon, SearchBoxIcon } from '../../assets/svgs/index';

const AddPens = ({ handleView, setSearchParams, setShowModal, setModalContext }) => {
    const [tableList, setTableList] = useState([]);
    const [tableName, setTableName] = useState('');
    const [columnList, setColumnList] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const getAllTables = async () => {
            try {
                const response = await axiosPrivate.get(`${getAPIMap('dataBaseTables')}`);
                setTableList(response.data.tables);
            } catch (error) {
                console.error('Error fetching tables:', error);
            }
        };
        getAllTables();
    }, []);

    const handleSubmit = async () => {
        try {
            const data = { "penlist": selectedColumns }
            console.log(data)
            const response = await axiosPrivate.post(`${getAPIMap("penlist")}`, data)
            if (response.status === 200) {
                setShowModal(true)
                setModalContext({
                    text: "Pen details added successfully. Check the pen list.",
                    title: "",
                })
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    useEffect(() => {
        const fetchColumnName = async () => {
            try {
                const response = await axiosPrivate.get(`${getAPIMap('dataBaseColumns')}?tableName=${tableName}`);
                setColumnList(response.data.columns);
            } catch (error) {
                console.error('Error fetching column names:', error);
            }
        };

        if (tableName) {
            fetchColumnName();
        }
    }, [tableName]);

    const navigateBack = () => {
        setSearchParams({ action: "view" });
    };

    const handleCheckboxChange = (column) => {
        const columnObject = {
            pen_name: "",  // Default pen_name, you can modify this dynamically
            table_name: tableName,
            column_name: column
        };

        // Check if the column is already selected
        if (selectedColumns.some(item => item.column_name === column)) {
            setSelectedColumns(selectedColumns.filter(item => item.column_name !== column));
        } else {
            setSelectedColumns([...selectedColumns, columnObject]);
        }
    };

    const handlePenNameChange = (index, newPenName) => {
        const updatedColumns = [...selectedColumns];
        updatedColumns[index].pen_name = newPenName;  // Update pen_name for the column at index
        setSelectedColumns(updatedColumns);
    };

    return (
        <div className='flex w-full my-4 justify-center items-center rounded-md'>
            <div className='w-8/12 mt-12 mb-4 max-h-[90vh] rounded-md bg-white py-4 flex flex-col'>
                <h1 className='text-black text-[16px]/[24px] font-semibold pl-4'>Add Pens</h1>

                <div className='flex w-full mt-3 flex-grow'>
                    <div className='w-3/12 border-r-2 border-borderColorBox'>
                        <h1 className='text-customGray text-[14px]/[21px] pl-4 font-medium'>Table Name</h1>
                        <div className='mt-2 relative'>
                            <input
                                type='text'
                                className='w-full border-t border-b border-l-0 border-r-0 pl-4 pr-10 border-borderColorBox placeholder:text-[12px]/[18px] placeholder:text-[#A9A3A3]'
                                placeholder='Search table name'
                            />
                            <div className='absolute right-4 top-1/2 transform -translate-y-1/2'>
                                <SearchBoxIcon />
                            </div>
                        </div>
                        <div className='pl-4 pt-2 max-h-[50vh] overflow-y-auto pr-2'>
                            {tableList?.map((table) => (
                                <div
                                    key={table}
                                    className={`text-customGray pl-2 ${tableName === table ? 'bg-textGrayHover' : ''} rounded-md hover:bg-textGrayHover text-[12px]/[18px] py-1 cursor-pointer`}
                                    onClick={() => { setTableName(table); setColumnList([]); setSelectedColumns([]); }}
                                >
                                    {table}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='w-3/12 border-r-2 max-h-[50vh] border-borderColorBox'>
                        <h1 className='text-customGray text-[14px]/[21px] pl-4 font-medium'>Column Name</h1>
                        <div className='mt-2 relative'>
                            <input
                                type='text'
                                className='w-full border-t border-b border-l-0 border-r-0 pl-4 pr-10 border-borderColorBox placeholder:text-[12px]/[18px] placeholder:text-[#A9A3A3]'
                                placeholder='Search column name'
                            />
                            <div className='absolute right-4 top-1/2 transform -translate-y-1/2'>
                                <SearchBoxIcon />
                            </div>
                        </div>
                        {columnList.length === 0 ? (
                            <div className='text-customGray text-center text-[14px] flex justify-center items-center pt-4'>
                                Select a table to get started
                            </div>
                        ) : (
                            <div className='pt-2 max-h-[50vh] overflow-y-auto'>
                                {columnList?.map((column) => (
                                    <div
                                        key={column}
                                        className={`text-customGray border-b border-borderColorBox flex items-center mx-2 pl-2 ${selectedColumns.some(item => item.column_name === column) ? 'bg-textGrayHover' : ''} rounded-md hover:bg-textGrayHover text-[12px]/[18px] py-2`}
                                    >
                                        <span className='flex-grow cursor-pointer'>{column}</span>
                                        <input
                                            type='checkbox'
                                            className='ml-2 mr-2'
                                            checked={selectedColumns.some(item => item.column_name === column)} // Check if column_name matches
                                            onChange={() => handleCheckboxChange(column)} // Update the selectedColumns array
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='w-6/12 px-4 border-r border-borderColorBox flex flex-col h-[60vh]'>
                        <h1 className='text-customGray text-[14px]/[21px] font-medium'>Pen list</h1>
                        <div className=' border rounded-md overflow-y-auto border-borderColorBox flex-grow'>
                            <div className='mt-2 relative mr-6'>
                                <input
                                    type='text'
                                    className='w-full border-t-0 border-b border-l-0 border-r-0 pl-4 pr-10 border-borderColorBox placeholder:text-[12px]/[18px] placeholder:text-[#A9A3A3]'
                                    placeholder='Search pen name'
                                />
                                <div className='absolute right-4 top-1/2 transform -translate-y-1/2'>
                                    <SearchBoxIcon />
                                </div>
                            </div>
                            <div className='mt-4 max-h-[60vh] overflow-y-auto h-full flex flex-col'>
                                {selectedColumns.length > 0 ? (
                                    selectedColumns.map((columnObj, index) => (
                                        <div key={index} className='flex mx-4 border-b border-borderColorBox items-center justify-between text-customGray pl-2 text-[12px]/[18px] py-2 mb-2'>
                                            <div className='flex-1 rounded-md text-black font-semibold h-full py-1 pl-2 bg-grayDarkBg'>{columnObj.column_name}</div>
                                            <input
                                                type='text'
                                                className='border border-borderColorBox focus:outline-none rounded-md px-2 py-1 text-[12px] text-customGray flex-1 mx-2 w-11/12'
                                                placeholder={`Enter user friendly name`}
                                                value={columnObj.pen_name}
                                                onChange={(e) => handlePenNameChange(index, e.target.value)}
                                            />
                                            <button
                                                className='text-red-500 text-[12px] px-2 py-1 hover:underline'
                                                onClick={() => {
                                                    const updatedColumns = selectedColumns.filter((_, idx) => idx !== index);
                                                    setSelectedColumns(updatedColumns);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <div className='flex flex-grow justify-center items-center text-center text-customGray text-[14px]'>
                                        No columns selected
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='w-full justify-end'>
                            <div className=' mt-4 flex justify-end gap-x-4'>
                                <button
                                    onClick={navigateBack}
                                    type='button'
                                    className='max-w-16 px-8 text-[#0F172A] text-semibold text-[14px]/[24px] flex justify-center items-center py-2 border border-borderColorBox rounded-md'
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={
                                        selectedColumns.length === 0 || !selectedColumns.every(item => item.pen_name)
                                    }
                                    className={`bg-baseOrange text-white min-w-32 px-4 flex justify-center items-center py-1 rounded-md text-[16px] font-medium ${selectedColumns.length === 0 || !selectedColumns.every(item => item.pen_name) ? "opacity-50" : ""}`}
                                >
                                    Save Penlist
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default AddPens;
