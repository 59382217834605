import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_URL;

export default axios.create({
    baseURL
    // headers: {
    //     "x-api-key": process.env.REACT_APP_API_KEY
    // }
});

// Axios instance for private routes (e.g., authentication, etc.)
export const axiosPrivate = axios.create({
    baseURL
    // , headers: {
    //     "x-api-key": process.env.REACT_APP_API_KEY
    // }
});
