import React, { useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MasterBatchSubmenu from '../MasterBatchSubmenu/index';
import NormalSidebar from '../NormalSidbar/index';

const Index = ({ 
    listData,
    handleColorChange,
    handleLineWidthChange,
    handleShowLogScale,
    batchesSent,
    minMaxValue,
    yAxisFormat,
    toggleVisibility,
    showLogScale,
    handleYAxisFormatChange,
    handleMaxValueChange,
    handleMinValueChange,
    minValues,
    maxValue 
}) => {
    const [openIndex, setOpenIndex] = useState(null); // State to track the currently open NormalSidebar

    const OpenSubMenu = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    // Separate master batch and normal batches
    const masterBatches = batchesSent.filter(batch => batch.isMasterBatch);
    const normalBatches = batchesSent.filter(batch => !batch.isMasterBatch);

    // Merge the two arrays while ensuring the master batches come first
    const allBatches = [...masterBatches, ...normalBatches];

    // Render the sidebar
    return (
        <div className='flex justify-end w-full'>
            <div className='w-full bg-[#f4f4f4] rounded-sm px-4 py-2'>
                <div className='w-full flex justify-start'>
                    <FileDownloadOutlinedIcon className='w-3 h-3 text-[#737373]' />
                </div>

                {/* Render the combined batches */}
                {allBatches.map((data, index) => (
                    <React.Fragment key={data.batchNumber}>
                        {data.isMasterBatch ? (
                            <MasterBatchSubmenu 
                                showLogScale={showLogScale} 
                                handleShowLogScale={handleShowLogScale} 
                                handleMaxValueChange={handleMaxValueChange} 
                                handleMinValueChange={handleMinValueChange} 
                                handleYAxisFormatChange={handleYAxisFormatChange} 
                                data={data} 
                                listData={listData}
                                yAxisFormat={yAxisFormat} 
                                minValues={minValues} 
                                maxValue={maxValue} 
                            />
                        ) : null}
                        <NormalSidebar 
                            data={data} 
                            OpenSubMenu={() => OpenSubMenu(index)} 
                            handleColorChange={handleColorChange}
                            handleLineWidthChange={handleLineWidthChange}
                            toggleVisibility={toggleVisibility}
                            isOpen={openIndex === index} 
                            index={index} 
                        />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Index;