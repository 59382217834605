import React, { useEffect, useState } from 'react';
import getAPIMap from '../routes/url/ApiUrls';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import { DeletePenIcon, EditPenIcon, NoPenListData, SearchBoxIcon, SmileyIconSuccess, SuccessIcon } from '../assets/svgs';
import { useSearchParams } from 'react-router-dom';
import AddPens from '../components/PenlistComps/AddPens';
import EditPens from '../components/PenlistComps/EditPens';
import DeletePens from '../components/PenlistComps/DeletePens';
import CustomModal from '../components/CustomModal/index'
const DetailedSidebar = () => {
    const [penlistData, setPenlistData] = useState([]);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("")
    const [searchParams, setSearchParams] = useSearchParams({ action: "view" });
    const [action, setAction] = useState("view");
    const id = searchParams.get("id");
    const [showModal, setShowModal] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [modalContext, setModalContext] = useState({
        text: "",
        title: "",
    });

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const getAllPenList = async () => {
            try {
                let url = getAPIMap("penlist")
                if (search) {
                    url += `?name=${search}`
                }
                const response = await axiosPrivate.get(url);
                setPenlistData(response.data);
            } catch (error) {
                setError('Failed to load Penlist data');
            }
        };

        getAllPenList();
    }, [action]);


    console.log(search)
    const handleView = (action, id = null) => {
        setAction(action);
        setSearchParams({ action, id });
    };
    useEffect(() => {

        if (!searchParams.has("action")) {
            setSearchParams({ action: "view" });
            setAction("view");
        } else {

            setAction(searchParams.get("action"));
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (showModal) {
            setSearchParams({ action: "success" });
        }
    }, [showModal])
    const handleTextBoxChange = (event) => {
        setSearch(event.target.value);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-70 bg-backgroundColor">
            {action === "view" ? (
                <div className="w-60 mt-8 shadow-lg bg-white fixed left-28 top-12 bottom-4 rounded-[8px] transition-all duration-300 z-30">
                    <div className="flex mt-2 justify-between items-center px-4">
                        <h3 className="text-lg font-semibold text-gray-800">Pen List</h3>
                        <div
                            className="text-[30px] text-[#EB7E39] hover:cursor-pointer"
                            onClick={() => handleView("add")}
                        >
                            +
                        </div>
                    </div>
                    <div className="mt-2 relative">
                        <input
                            type="text"
                            className="w-full border placeholder:text-[#A9A3A3] outline-none border-top-1 border-b-1 border-l-0 border-r-0 pl-4 pr-10 border-borderColorBox placeholder:text-[12px]/[18px]"
                            placeholder="Search Pen"
                            value={search}
                            onChange={handleTextBoxChange}
                        />
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <SearchBoxIcon />
                        </div>
                    </div>

                    <div className="mt-2">
                        {penlistData?.length === 0 ? (
                            <div className="flex justify-center items-center">
                                <NoPenListData className="w-16 h-16" />
                            </div>
                        ) : (
                            <ul className="px-2">
                                {penlistData?.map((pen) => (
                                    <li
                                        key={pen.id}
                                        className="flex text-[12px] border-b border-borderColorBox text-customGray justify-between items-center hover:bg-hoverOrange hover:border-b-0 hover:rounded-md p-2"
                                    >
                                        <div className='text-customGray text-[12px]/[18px]'>{pen.pen_name}</div>
                                        <div className="flex gap-x-4">
                                            <div onClick={() => handleView("delete", pen.id)} className='hover:cursor-pointer'> <DeletePenIcon /></div>
                                            <div onClick={() => handleView("edit", pen.id)} className='hover:cursor-pointer'>     <EditPenIcon /></div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            ) : action === "add" ? (
                <AddPens handleView={handleView} setModalContext={setModalContext} setShowModal={setShowModal} setSearchParams={setSearchParams} />
            ) : action === "edit" ? (
                <EditPens penId={id} setSearchParams={setSearchParams} setModalContext={setModalContext} showModal={showModal} setShowModal={setShowModal} />
            ) : action === "delete" ? (
                <DeletePens penId={id} setSearchParams={setSearchParams} setModalContext={setModalContext} showModal={showModal} setShowModal={setShowModal} />
            ) : null}
            <CustomModal showModal={showModal} modalContext={modalContext} icon={<SuccessIcon />} closeModal={() => { setShowModal(false) }} navigate={() => { setSearchParams({ action: "view" }); setShowModal(false) }} />
        </div>
    );
};

export default DetailedSidebar;
