import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputField from '../components/InputField';
import Select from '../components/SelectBox';
import { PATHS } from '../routes/path'
import { useNavigate, useLocation } from 'react-router-dom';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate'
import getApiMap from '../routes/url/ApiUrls'
import Notification from '../../src/components/Notifications/index';
import DbNotification from '../../src/components/Notifications/DbNotification';

const DBConnection = () => {
    const axiosPrivate = useAxiosPrivate()
    const [tableOptions, setTableOptions] = useState([])
    const [columnoptions, setColumnOptions] = useState([])
    const [successMessage, setSuccessMessage] = useState(false)
    const [lastSubmission, setLastSubmission] = useState(null);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const [dbsuccessMessage, setDbSuccessMessage] = useState(false)
    const [dbNotificationOpen, setDbNotificationOpen] = useState(false);
    const [dbNotificationMessage, setDbNotificationMessage] = useState('');
    const [dbNotificationSeverity, setDbNotificationSeverity] = useState('success');
    const [dbDetails, setDbDetails] = useState(null);

    const navigate = useNavigate();

    // useEffect(() => {
    //     const getDataBaseTables = async () => {
    //         try {
    //             const response = await axiosPrivate.get(`${getApiMap("dataBaseTables")}`)
    //             const tables = response.data.tables.map((table) => {
    //                 return { label: table, value: table }
    //             })
    //             setTableOptions(tables)
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    //     getDataBaseTables()
    // }, [])

    const handleNotificationClose = () => {
        setNotificationOpen(false);
        setNotificationMessage('');
    };

    const handleDbNotificationClose = () => {
        setDbNotificationOpen(false);
        setDbNotificationMessage('');
    };
    const validationSchema = Yup.object({
        serverType: Yup.string()
            .required('Server type is required')
            .min(1, 'Server type is required'),
        path: Yup.string()
            .min(1, 'Path is required'),
        queryType: Yup.string()
            .required('Query type is required')
            .min(1, 'Query type is required'),
        databaseName: Yup.string()
            .required('Database name is required')
            .min(1, 'Database name is required'),
        userName: Yup.string()
            .min(3, 'Username must be at least 3 characters long')
            .min(1, 'Username is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long')
            .min(1, 'Password is required'),
        port: Yup.string()
            .matches(/^[0-9]+$/, 'Port must be a valid number')
            .min(1, 'Port is required'),
        tableName: Yup.string()
            .required('Table name is required')
            .min(1, 'Table name is required'),
        dateTimeColumn: Yup.string()
            .required('DateTime column name is required')
            .min(1, 'DateTime column name is required'),
        batchNumberColumn: Yup.string()
            .required('Batch number column name is required')
            .min(1, 'Batch number column name is required'),
        jobNumberColumn: Yup.string()
            .required('Job number column name is required')
            .min(1, 'Job number column name is required'),
        Username_dateTimeColumn: Yup.string()
            .required('User name for DateTime column is required')
            .min(1, 'User name for DateTime column is required'),
        Username_batchNumberColumn: Yup.string()
            .required('User name for Batch number column is required')
            .min(1, 'User name for Batch number column is required'),
        Username_jobNumberColumn: Yup.string()
            .required('User name for Job number column is required')
            .min(1, 'User name for Job number column is required'),
    });

    const databaseOptions = [
        { label: 'MSAccess', value: 'MSAccess' },
        { label: 'MSSQL', value: 'MSSQL' },
    ];

    const queryOptions = [
        { label: 'MSaccess', value: 'MSaccess' },
        { label: 'SQL Server', value: 'SQL Server' },
    ];

    const navigateBack = () => {
        navigate('/')
    }
    const initialValues = {
        serverType: '',
        path: "",
        queryType: '',
        databaseName: '',
        userName: '',
        password: '',
        port: '',
        tableName: '',
        dateTimeColumn: '',
        batchNumberColumn: '',
        jobNumberColumn: '',
        Username_dateTimeColumn: '',
        Username_batchNumberColumn: '',
        Username_jobNumberColumn: ''
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await axiosPrivate.post(`${getApiMap("databaseBase")}`, values);
                if (response.status === 200) {
                    setNotificationOpen(true);
                    setNotificationMessage('Database successfully connected.');
                    setNotificationSeverity('success');
                    setDbDetails(response.data);
                }

            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    setDbNotificationOpen(true);
                    setDbNotificationMessage('Failed to connect to the database. Please check your credentials.');
                    setDbNotificationSeverity('error');
                } else {
                    console.error("Error connecting to the database:", error);
                }

            } finally {
                setSubmitting(false);
            }
        },
    });


    return (
        <>
            <div className="fixed inset-0 w-full h-screen flex items-center bg-opacity-80 bg-backgroundColor z-30 justify-center backdrop-blur-[5px]">
                <div className="w-7/12 bg-white p-8 rounded-md shadow-lg mt-16 overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                    <div className="flex items-center relative">

                        <button onClick={() => { formik.resetForm(); navigateBack(); }} className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl">
                            &times;
                        </button>
                        <div className="text-textHeading2 pt-2 text-[24px]/[28px] font-bold">Connect to Database</div>
                    </div>
                    <div className="text-textGray text-[16px]/[20px] pt-1">Fill the form to connect the Db</div>

                    <form onSubmit={formik.handleSubmit} className="w-full mt-4">
                        <div className="w-full flex gap-x-4">
                            <div className="w-1/2">

                                <InputField
                                    label="Path"
                                    type="text"
                                    name="path"
                                    value={formik.values.path}
                                    onChange={formik.handleChange}
                                    error={formik.touched.path && formik.errors.path}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="MSSQL Server"
                                />

                                {/* <Select
                                    label="Query Type"
                                    name="queryType"
                                    options={queryOptions}
                                    value={formik.values.queryType}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        formik.setFieldValue('queryType', selectedValue);

                                        // Update serverType if required
                                        formik.setFieldValue('serverType', selectedValue);
                                    }}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                /> */}
                                <Select
                                    label="Database"
                                    name="serverType"
                                    options={databaseOptions}
                                    value={formik.values.serverType}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        formik.setFieldValue('serverType', selectedValue);
                                    }}
                                    error={formik.touched.serverType && formik.errors.serverType}
                                    placeholder="Choose database server"
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                />

                                <InputField
                                    label="User Name"
                                    type="text"
                                    name="userName"
                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.userName && formik.errors.userName}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Admin"
                                />
                                <InputField
                                    label="Port"
                                    type="text"
                                    name="port"
                                    value={formik.values.port}
                                    onChange={formik.handleChange}
                                    error={formik.touched.port && formik.errors.port}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="6565"
                                />

                                <InputField
                                    label="Specific column name to retrieve date and time"
                                    type="text"
                                    name="dateTimeColumn"
                                    value={formik.values.dateTimeColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dateTimeColumn && formik.errors.dateTimeColumn}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Value"
                                />
                                <InputField
                                    label="Specific column name to retrieve batch number"
                                    type="text"
                                    name="batchNumberColumn"
                                    value={formik.values.batchNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.batchNumberColumn && formik.errors.batchNumberColumn}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label="Specific column name to retrieve job description"
                                    type="text"
                                    name="jobNumberColumn"
                                    value={formik.values.jobNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.jobNumberColumn && formik.errors.jobNumberColumn}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Value"
                                />

                            </div>
                            <div className="w-1/2">


                                {/* <InputField
                                label="Server Type"
                                type="text"
                                name="serverType"
                                value={formik.values.serverType}
                                onChange={formik.handleChange}
                                error={formik.touched.serverType && formik.errors.serverType}
                                labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[14px]/[24px]"
                                placeholder="MSSQL Server"
                            /> */}
                                <InputField
                                    label="Database name"
                                    type="text"
                                    name="databaseName"
                                    value={formik.values.databaseName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.databaseName && formik.errors.databaseName}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Database name"
                                />
                                <InputField
                                    label="Password"
                                    type="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && formik.errors.password}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="********"
                                />

                                <InputField
                                    label="Table name"
                                    name="tableName"
                                    value={formik.values.tableName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.tableName && formik.errors.tableName}
                                    placeholder="Table1"
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                />

                                <InputField
                                    label="User given name for the retrieved date and time"
                                    type="text"
                                    name="Username_dateTimeColumn"
                                    value={formik.values.Username_dateTimeColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_dateTimeColumn && formik.errors.Username_dateTimeColumn}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Value"
                                />
                                <InputField
                                    label="User given name for the retrieved batch number"
                                    type="text"
                                    name="Username_batchNumberColumn"
                                    value={formik.values.Username_batchNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_batchNumberColumn && formik.errors.Username_batchNumberColumn}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label="User given name for the retrieved job description"
                                    type="text"
                                    name="Username_jobNumberColumn"
                                    value={formik.values.Username_jobNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_jobNumberColumn && formik.errors.Username_jobNumberColumn}
                                    labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    placeholder="Enter Value"
                                />

                            </div>
                        </div>
                        {console.log(formik.errors)}
                        <div className='flex w-full gap-x-4 mt-2 justify-end'>
                            <button type='button' className='w-24 h-10 flex justify-center items-center border border-borderColorBox rounded-md text-customGray text-[14px]/[24px]' onClick={navigateBack}>Cancel</button>
                            <button
                                type="submit"
                                disabled={!(formik.isValid && formik.dirty)}
                                className={`flex justify-center items-center py-2 px-1 h-[40px] bg-baseOrange w-48 text-[16px] font-semibold text-white rounded-md ${formik.isValid && formik.dirty ? "opacity-100" : "opacity-40"}`}
                            >
                                Connect to Database
                            </button>
                        </div>
                        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
                    </form>
                </div>
            </div>
            {notificationOpen && (
                <Notification
                    open={notificationOpen}
                    message={notificationMessage}
                    severity={notificationSeverity}
                    onClose={handleNotificationClose}
                />
            )}
            {dbNotificationOpen && (
                <DbNotification
                    open={dbNotificationOpen}
                    message={dbNotificationMessage}
                    severity={dbNotificationSeverity}
                    onClose={handleDbNotificationClose}
                    dbDetails={dbDetails} // Pass dbDetails to the notification
                />
            )}
        </>
    );
};

export default DBConnection;
