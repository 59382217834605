import React, { useEffect, useCallback, useState } from 'react';
import CustomSelectWithDateRange from '../CustomSelectWithDate';
import CustomSearchableSelect from '../SelectWithSubOptionsSelectable';

const BatchFilter = React.memo(({
    batchList,
    batchOptionSelected,
    setBatchOptionsSelected,
    groupList,
    data,
    toggleVisibility,
    handleReset,
    setGroupList,
    groupOptionSelected,
    dataRange,
    groupData,
    setDateRange,
    handleFetchData,
    setGroupOptionsSelected,
}) => {

    const [handleDateRender, setHandleDateRender] = useState(false);

    const handleOptionChange = useCallback((newSelectedOptions) => {
        setBatchOptionsSelected(newSelectedOptions);
    }, [setBatchOptionsSelected]);

    const handleOptionChange2 = (newSelectedOptions) => {
        console.log("Reached here")
        setGroupOptionsSelected(newSelectedOptions);

    }

    const handleFunctionExecute = useCallback(() => {
        if (data.length > 0) {
            handleFetchData();
            // handleReset();
            // setHandleDateRender(true);
        } else {
            handleFetchData();
        }
    }, [data, handleFetchData, handleReset]);
console.log(groupList)
    return (
        <div className="pt-3">
            <div className={`shadow-lg ${groupData.length === 0 ? 'h-16' : 'h-28'} pt-3 flex-col items-center mx-4 w-[98%] rounded-md bg-bgBatchDetailsFilter`}>
                <div className="w-full px-2 flex gap-x-1 justify-between items-center">
                    <div className='w-full flex gap-x-2'>
                        <CustomSelectWithDateRange
                            batchList={batchList}
                            batchOptionSelected={batchOptionSelected}
                            setBatchOptionsSelected={setBatchOptionsSelected}
                            dataRange={dataRange}
                            handleDateRender={handleDateRender}
                            setDateRange={setDateRange}
                            label={"Add  batches for comparison "}
                            setHandleDateRender={setHandleDateRender}
                            handleOptionChange={handleOptionChange}
                        />
                        <CustomSearchableSelect
                            options={groupList}
                            showLabel={false}
                            selectedOptions={groupOptionSelected}
                            onChange={handleOptionChange2}
                            multiSelect={false}
                            selectMaxCount={1}
                            placeholder="Select group from the drop down"
                            showParameter={true}
                        />
                    </div>
                    <div className='w-4/12'>
                        <button
                            className='w-11/12 text-[14px] h-10 bg-baseOrange rounded-md text-white justify-end'
                            onClick={handleFunctionExecute}
                        >
                            {data.length > 0 ? "Reset" : "Apply Filters"}
                        </button>
                    </div>
                </div>
                <div className="flex gap-x-2 !z-50 pl-2">
                {groupData.length > 0 && groupData.map((group, index) => {
                    const backgroundColor = group.color; // Fallback if color is not set
                    console.log(groupData)
                    return (
                        <div
                            key={group.id}
                            style={{
                                backgroundColor: group.visibility ? backgroundColor : '#f9f9f9',
                                color:group.visibility ? "white" : 'black' // Apply color correctly
                            }}
                            className={`p-2 text-white rounded-full text-[12px] mt-2 cursor-pointer`}
                            onClick={() => toggleVisibility(index)} // Trigger visibility toggle
                        >
                            <p>{group?.batchNumber}</p>
                        </div>
                    );
                })}
            </div>
            </div>


        </div>
    );
});

export default BatchFilter;