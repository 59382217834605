import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import { CircularProgress } from '@mui/material';

const AdminWrapper = () => {
    const { auth, initialLoader, setInitialLoader } = useContext(AuthContext);

    useEffect(() => {
        setTimeout(() => {
            setInitialLoader(false);
        }, 800);
    }, [setInitialLoader]);

    if (initialLoader) {
        return (
            <div className="w-full h-screen flex justify-center items-center">
                <div className="flex-col items-center justify-center text-center">
                    <CircularProgress
                        size={70}
                        sx={{
                            color: "#555555",
                        }}
                    />
                    <div className="mt-2 text-customGray text-[32px]">Loading...</div>
                </div>
            </div>
        );
    }

    if (!auth || !auth.id) {
        return <Navigate to="/sign-in" replace />;
    }

    return (
        <div className="w-full overflow-y-auto overflow-x-hidden h-full bg-primaryGradient">
            <header className="fixed top-0 w-full z-50">
                <Navbar name={auth.name} />
            </header>

            <div className="flex h-full overflow-y-auto overflow-x-hidden">
                <aside className="fixed z-40 left-0 top-12">
                    <Sidebar />
                </aside>

                <main className="relative w-full ml-28 pt-24 mb-10 px-8">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default AdminWrapper;
