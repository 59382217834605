import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GroupIcon, DatabaseIcon, MasterBatchIcon, PenlistIcon, UserIcon } from '../../assets/svgs';
import { AuthContext } from '../../context/AuthContext';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import DbNotification from '../Notifications/DbNotification';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activePath, setActivePath] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const axiosPrivate = useAxiosPrivate()

    const [dbNotificationOpen, setDbNotificationOpen] = useState(false);
    const [dbNotificationMessage, setDbNotificationMessage] = useState('');
    const [dbNotificationSeverity, setDbNotificationSeverity] = useState(''); // You can use this for additional styling of notifications

    const { fetchRoles } = useContext(AuthContext);

    const handleDbNotificationClose = () => {
        setDbNotificationOpen(false);
        setDbNotificationMessage('');
    };

    const SidebarMenu = [
        { name: "Group", icon: GroupIcon, path: '/group' },
        { name: "PenList", icon: PenlistIcon, path: '/pen' },
        { name: "User", icon: UserIcon, path: '/user' },
        { name: "Database", icon: DatabaseIcon, path: '/db' }
    ];

    const checkDatabaseConnection = async () => {
        try {
            const response = await axiosPrivate.get('/database'); // Replace with your actual endpoint
            if (response.status === 200) {
                setIsConnected(true);
                return true;
            }
            return false; // Not connected
        } catch (error) {
            console.error("Error checking database connection:", error);
            return false;
        }
    };

    const handleItemClick = async (item) => {
        if (item.path === '/db') {
            const connected = await checkDatabaseConnection();
            if (connected) {
                setDbNotificationOpen(true);
                setDbNotificationMessage('Already connected to the database.');
                setDbNotificationSeverity('success');
            } else {
                navigate(item.path);
            }
        } else {
            if (activePath === item.path) {
                setActivePath('');
                navigate('/');
            } else {
                if (item.path === '/user') {
                    fetchRoles(); // Call the fetchRoles function if User is clicked
                }
                // Set the active path and navigate to the selected item
                setActivePath(item.path);
                navigate(item.path);
            }
        }
    };

    return (
        <div className="flex relative">
            <div className="sidebar w-24 ml-2 rounded-lg shadow-lg h-[650px] bg-transparent text-white fixed left-0 top-0 bg-primaryGradient z-40 mt-20">
                <ul className="flex justify-center items-center flex-col mt-6">
                    {SidebarMenu.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => handleItemClick(item)}
                            className="cursor-pointer mb-4 rounded flex flex-col justify-center items-center"
                        >
                            <div className={`hover:bg-[#EB7E39] ${activePath === item.path ? "bg-[#EB7E39]" : ""} flex justify-center items-center rounded-full w-12 h-12`}>
                                <item.icon className="!w-6 !h-6" />
                            </div>
                            <span className="text-center mt-2 text-sm">{item.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
            {/* Notification UI */}
            {dbNotificationOpen && (
                <DbNotification
                    open={dbNotificationOpen}
                    message={dbNotificationMessage}
                    severity={dbNotificationSeverity}
                    onClose={handleDbNotificationClose}
                />
            )}
        </div>
    );
};

export default Sidebar;
