import React, { useState, useEffect, useRef } from 'react';
import { Chip, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const CustomSearchableSelect = ({
    options = [],
    selectedOptions = [],
    onChange,
    placeholder = 'Select Options',
    multiSelect = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    // Transform options into dropdown-compatible format
    const transformedOptions = options.map(group => ({
        label: group.label,
        options: group.penIds
            .filter(pen => pen.pen_id && pen.pen_name) // Filter valid pen items
            .map(pen => ({
                label: pen.pen_name,
                value: pen.pen_id,
            })),
    }));

    const filteredOptions = transformedOptions.map(group => ({
        ...group,
        options: group.options.filter(option =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase())
        ),
    })).filter(group => group.options.length > 0);

    const toggleOption = (value, label) => {
        const newSelection = { value, label };
        if (multiSelect) {
            // Check if the option is already selected
            const alreadySelected = selectedOptions.some(option => option.value === value);
            if (alreadySelected) {
                // If selected, remove it
                onChange(selectedOptions.filter(option => option.value !== value));
            } else {
                // If not selected, add it
                onChange([...selectedOptions, newSelection]);
            }
        } else {
            // For single selection, just set the new selection and close the dropdown
            onChange([newSelection]);
            setIsOpen(false);
        }
    };

    const handleRemoveChip = (value) => {
        onChange(selectedOptions.filter(option => option.value !== value));
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <div
                style={{
                    border: '1px solid #EB7E39',
                    borderRadius: '4px',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px',
                    cursor: 'pointer',
                    minWidth: '300px',
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',  // Ensure overflow is hidden
                    whiteSpace: 'nowrap', // Prevent line break for selected chips
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div style={{
                    display: 'flex',
                    flexWrap: 'nowrap',  // Prevent chips from wrapping to the next line
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', // Ensure chips don't overflow
                    maxWidth: '200px',  // Limit the max width of selected chips
                    paddingRight: '8px', // Add space for the dropdown icon
                    alignItems: 'center',
                }}>
                    {selectedOptions.length > 0 ? (
                        selectedOptions.map(option => (
                            <Chip
                                key={option.value}
                                label={option.label}
                                onDelete={() => handleRemoveChip(option.value)}
                                deleteIcon={<CloseIcon />}
                                style={{
                                    margin: '2px',
                                    backgroundColor: '#F5F5F5',
                                    color: '#333',
                                    maxWidth: '150px',  // Limit chip width
                                    whiteSpace: 'nowrap',  // Prevent chip label from breaking
                                    overflow: 'hidden',  // Hide overflow text
                                    textOverflow: 'ellipsis',  // Add ellipsis for long labels
                                }}
                            />
                        ))
                    ) : (
                        <span style={{ color: '#979797', fontSize: '12px' }}>
                            {placeholder}
                        </span>
                    )}
                </div>
                <ExpandMoreIcon style={{ marginLeft: 'auto', color: '#979797' }} />
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        marginTop: '10px',
                        width: '130%',
                        border: '1px solid #EB7E39',
                        borderRadius: '8px',
                        backgroundColor: '#FFFFFF',
                        zIndex: 1000,
                        maxHeight: '300px',
                        overflowY: 'auto',
                    }}
                >
                    <div style={{ padding: '8px', display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            style={{
                                flexGrow: 1,
                                padding: '8px',
                                border: '1px solid #9a9a9a',
                                borderRadius: '4px',
                            }}
                        />
                        <SearchIcon style={{ marginLeft: '8px', color: '#979797' }} />
                    </div>

                    {filteredOptions.map(group => (
                        <div key={group.label}>
                            <div
                                style={{
                                    padding: '8px',
                                    fontWeight: 'bold',
                                    borderBottom: '1px solid #737373',
                                }}
                            >
                                {group.label}
                            </div>
                            {group.options.map(option => (
                                <div
                                    key={option.value}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleOption(option.value, option.label)}
                                >
                                    <span>{option.label}</span>
                                    {multiSelect && (
                                        <Checkbox
                                            checked={selectedOptions.some(o => o.value === option.value)}
                                            sx={{
                                                color: '#EB7E39',
                                                '& .MuiSvgIcon-root': {
                                                    color: selectedOptions.some(o => o.value === option.value)
                                                        ? '#EB7E39'
                                                        : 'default',
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSearchableSelect;
