import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import AddUser from './AddUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import Notification from '../Notifications/index';
import { NoUserIcon, SearchBoxIcon, DeletePenIcon, EditPenIcon } from '../../assets/svgs';
import { addNewUser } from '../../api/UserUrl';
import '../../App.css';
const DownArrowIcon = ({ className, style }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.75 1.5L7.57226 6.21869C7.76995 6.37891 8.05643 6.3651 8.23779 6.18662L13 1.5"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const UpArrowIcon = ({ className, style }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.75 6.5L7.57226 1.78131C7.76995 1.62109 8.05643 1.6349 8.23779 1.81338L13 6.5"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const UserPage = ({ userId }) => {
  const {
    fetchUsersByRole,
    getUserDetailsById,
    role,
    addNewUser,
    editUser,
    deleteUser,
  } = useContext(AuthContext);

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userId) {
        try {
          const data = await getUserDetailsById(userId);
          setSelectedUser(data);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };
    fetchUserDetails();
  }, [userId, getUserDetailsById]);

  useEffect(() => {
    const getUsers = async () => {
      if (selectedRole) {
        const usersByRole = await fetchUsersByRole(selectedRole);
        setUsers(usersByRole);
        setFilteredUsers(usersByRole);
      }
    };
    getUsers();
  }, [selectedRole, fetchUsersByRole]);

  useEffect(() => {
    if (role.length > 0) {
      setSelectedRole(role[0].id); // Set the initial role
    }
  }, [role]);

  const handleSelect = (role) => {
    setSelectedRole(role.id);
    setIsOpen(false);
    fetchUsersByRole(role.id); // Fetch users for the selected role immediately after selection
  };

  const handleEditUserOpen = async (user) => {
    try {
      setSelectedUserId(user.id);
      const userDetails = await getUserDetailsById(user.id);
      setSelectedUser(userDetails);
      setEditUserOpen(true);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleEditUserClose = () => {
    setSelectedUser(null);
    setSelectedUserId(null);
    setEditUserOpen(false);
  };


  const refreshUsers = async () => {
    if (selectedRole) {
      const usersByRole = await fetchUsersByRole(selectedRole);
      setUsers(usersByRole);
      setFilteredUsers(usersByRole);
    }
  };

  const handleAddUser = async (user) => {
    try {
      const addedUser = await addNewUser(user);
      setNotificationMessage('User added successfully. Check the user list.');
      setNotificationSeverity('success');
      await refreshUsers(); // Refresh users after adding
    } catch (err) {
      const errorMessage = err?.response?.data?.message || 'Something went wrong. We were not able to add the user. Try again.';
      setNotificationMessage(errorMessage);
      setNotificationSeverity('error');
    }
    setAddUserOpen(false);
    setNotificationOpen(true);
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      const response = await editUser(selectedUserId, updatedUser);
      setNotificationMessage('User edited successfully. Check the user details.');
      setNotificationSeverity('success');
      await refreshUsers(); // Refresh users after updating
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Something went wrong. We were not able to update the user details. Try again.';
      setNotificationMessage(errorMessage);
      setNotificationSeverity('error');
    }
    handleEditUserClose();
    setNotificationOpen(true);
  };

  const handleDeleteUserOpen = (userId) => {
    setSelectedUserId(userId);
    setDeleteUserOpen(true);
  };

  const handleDeleteUserClose = () => {
    setDeleteUserOpen(false);
    setSelectedUserId(null);
  };

  const deleteUserHandler = async () => {
    try {
      await deleteUser(selectedUserId);
      setNotificationMessage('User deleted successfully.');
      setNotificationSeverity('success');
      await refreshUsers(); // Refresh users after deleting
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Something went wrong. We were not able to delete the user. Try again.';
      setNotificationMessage(errorMessage);
      setNotificationSeverity('error');
    }
    handleDeleteUserClose();
    setNotificationOpen(true);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const onFilter = (query) => {
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onFilter(query);  // Filter immediately as the user types
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-opacity-70 bg-backgroundColor">
        <div className="w-[260px] h-[881px] mt-8 shadow-lg bg-white fixed left-[105px] top-[49px] bottom-2 rounded-[8px] transition-all duration-300 z-30">
          <div className="flex mt-2 justify-between items-center px-4">
            <h3 className="text-lg font-semibold text-gray-800">User Management</h3>
            <div
              className="text-[30px] text-[#EB7E39] hover:cursor-pointer"
              onClick={() => setAddUserOpen(true)}
            >
              +
            </div>
          </div>

          <div className="mt-2 flex items-center">
            {/* Dropdown */}
            <div className="relative flex-shrink-0">
              <div
                className={`border placeholder:text-[#A9A3A3] border-[#A9A3A3] outline-none border-t border-b border-l-0 border-r-0 pl-6
                ${selectedRole ? 'bg-[#A7D49A] text-[#555555] text-[12px]' : 'bg-white text-[12px] text-[#555555]'}
                cursor-pointer flex items-center justify-between h-8 w-24`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedRole ? role.find(r => r.id === selectedRole)?.name : "Select a role"}
                {isOpen ? (
                  <UpArrowIcon className="mr-1" />
                ) : (
                  <DownArrowIcon className="mr-1" />
                )}
              </div>
              {isOpen && (
                <div className="absolute mt-1 bg-white border border-borderColorBox rounded shadow-lg w-full">
                  {Array.isArray(role) && role.length > 0 ? (
                    role.map((r) => (
                      <div
                        key={r.id}
                        onClick={() => handleSelect(r)}
                        className="text-[12px] p-2 hover:bg-[#A7D49A] hover:text-[#555555] cursor-pointer"
                      >
                        {r.name}
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-[#555555] text-[12px] p-2">No roles</div>
                  )}
                </div>
              )}
            </div>

            {/* Search Bar */}
            <div className="relative flex-grow ">
              <input
                type="text"
                className="w-full border-l border-[#A9A3A3] placeholder:text-[#A9A3A3] outline-none border-t border-b border-r-0 pr-8 h-8 text-[12px] text-[#A9A3A3]"
                placeholder="   Search user"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <SearchBoxIcon />
              </div>
            </div>
          </div>

          <div className="overflow-y-auto max-h-[550px] scrollbar-thin">
            {filteredUsers.length === 0 ? (
              <div className="flex flex-col items-center py-20">
                <NoUserIcon className="w-16 h-16 mb-2 text-gray-400" />
                <p className="text-gray-600">No users to display</p>
              </div>
            ) : (
              <ul>
                {filteredUsers.map((user) => (
                  <li
                    key={user.id}
                    className="flex text-[12px] border-b border-borderColorBox text-customGray justify-between items-center hover:bg-hoverOrange hover:border-b-0 hover:rounded-md p-2"
                  >
                    <div className='text-customGray text-[12px]/[18px]'>{user.name}</div>
                    <div className="flex gap-x-4">
                      <div onClick={() => handleDeleteUserOpen(user.id)} className='hover:cursor-pointer'>
                        <DeletePenIcon />
                      </div>
                      <div onClick={() => handleEditUserOpen(user)} className='hover:cursor-pointer'>
                        <EditPenIcon />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <AddUser
        open={addUserOpen}
        handleClose={() => setAddUserOpen(false)}
        addUser={handleAddUser}
      />
      <EditUser
        open={editUserOpen}
        handleClose={() => {
          setSelectedUser(null);
          setSelectedUserId(null);
          setEditUserOpen(false);
        }}
        user={selectedUser}
        editUser={handleUpdateUser}
      />
      <DeleteUser
        open={deleteUserOpen}
        onClose={handleDeleteUserClose}
        onDelete={deleteUserHandler}
      />
      <Notification
        open={notificationOpen}
        message={notificationMessage}
        onClose={handleNotificationClose}
        severity={notificationSeverity}
      />
    </>
  );
};

export default UserPage;
