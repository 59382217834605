import React from 'react';
import Sidebar from '../components/Sidebar/index';
import Navbar from '../components/Navbar';
import UserPage from '../components/User';
import GroupPage from '../components/GroupComps';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DBConnection from './DBConnection';
import BatchDetails from './BatchDetails';
import DetailedSidebar from './Penlist';

const TestingPages = () => {
  const primaryGradient = 'linear-gradient(217.36deg, #6099E8 27.99%, #23487C 56.51%)';

  return (
    <div className="w-full h-screen" style={{ background: primaryGradient }}>
      <div className="fixed top-0 w-full z-40">
        <Navbar />
      </div>

      <div className="flex h-full">
        <div className="fixed z-50 left-0 top-12 h-full bottom-0">
          <Sidebar />
        </div>

        <div className="relative flex-1 ml-24 mt-52 ">
          {/* BatchDetails is positioned to always be behind content */}
          <div className="absolute inset-0 flex items-center justify-center backdrop-blur-sm z-0">
            <BatchDetails />
          </div>

          <div className="relative z-10">
            <Routes>
              <Route path="/" element={<BatchDetails />} />
              <Route path="/user" element={<UserPage />} />
              <Route path="/pen" element={<DetailedSidebar />} />
              <Route path="/db" element={<DBConnection />} />
              <Route path="/group" element={<GroupPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingPages;
