import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import AddGroup from './AddGroup';
import EditGroup from './EditGroup';
import DeleteGroup from './DeleteGroup';
import Notification from '../Notifications/index';
import { NoUserIcon, SearchBoxIcon, DeletePenIcon, EditPenIcon } from '../../assets/svgs';

const DownArrowIcon = ({ className, style }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.75 1.5L7.57226 6.21869C7.76995 6.37891 8.05643 6.3651 8.23779 6.18662L13 1.5"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const UpArrowIcon = ({ className, style }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.75 6.5L7.57226 1.78131C7.76995 1.62109 8.05643 1.6349 8.23779 1.81338L13 6.5"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const GroupPage = ({ groupId }) => {
  const {
    fetchGroups,
    getGroupDetailsById,
    editGroup,
    deleteGroup,
    addNewGroup,
    UpdateGroupPenDetail
  } = useContext(AuthContext);

  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [deleteGroupOpen, setDeleteGroupOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedGroupType, setSelectedGroupType] = useState('Admin'); // Set default to 'Admin'
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  // Map displayed group types to lowercase values
  const groupTypeMap = {
    Admin: 'admin',
    Private: 'private',
    Public: 'public',
  };

  const groupTypes = Object.keys(groupTypeMap);

  useEffect(() => {
    const fetchGroupDetails = async () => {
      if (groupId) {
        try {
          const data = await getGroupDetailsById(groupId);
          setSelectedGroup(data);
        } catch (error) {
          console.error("Error fetching group details:", error);
        }
      }
    };
    fetchGroupDetails();
  }, [groupId, getGroupDetailsById]);

  useEffect(() => {
    const getGroups = async () => {
      if (selectedGroupType) {
        setLoading(true);
        try {
          const groupsByType = await fetchGroups({
            type: groupTypeMap[selectedGroupType],
            limit: 100,
            page: 1,
          });
          setGroups(groupsByType || []);
          setFilteredGroups(groupsByType || []);
        } catch (err) {
          setError("Error fetching groups");
        } finally {
          setLoading(false);
        }
      }
    };
    getGroups();
  }, [selectedGroupType]);
  console.log("groups", groups)
  const handleAddGroupOpen = () => setAddGroupOpen(true);
  const handleAddGroupClose = () => {
    setAddGroupOpen(false);
  };

  const handleEditGroupOpen = (group) => {
    setSelectedGroup(group);
    setSelectedGroupId(group.id);
    setEditGroupOpen(true);
  };

  const handleEditGroupClose = () => {
    setSelectedGroup(null);
    setSelectedGroupId(null);
    setEditGroupOpen(false);
  };

  const handleAddGroup = async (group) => {
    try {
      const addedGroup = await addNewGroup(group);
      setNotificationMessage('Group added successfully. Check group list.');
      setNotificationSeverity('success');
      setGroups((prevGroups) => [...prevGroups, addedGroup]);

    } catch (err) {

      setNotificationSeverity('success');
    }
    setAddGroupOpen(false);
    setNotificationOpen(true);
    fetchGroupsAfterAction();
  };

  const handleUpdateGroup = async (penGroupId, updatedGroupDetails) => {
    try {
      console.log("updatedGroupDetails", updatedGroupDetails)
      const response = await UpdateGroupPenDetail(penGroupId, updatedGroupDetails);
      setNotificationMessage('Group Pen details added successfully. Check group list.');
      setNotificationSeverity('success');
      const updatedGroup = response.groupDetail || response;

      setGroups((prevGroups) =>
        prevGroups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group)),
      );

    } catch (error) {

    }
    setEditGroupOpen(false);
    setNotificationOpen(true);
    fetchGroupsAfterAction();

  };

  const handleDeleteGroupOpen = (groupId) => {
    setSelectedGroupId(groupId);
    setDeleteGroupOpen(true);
  };

  const handleDeleteGroupClose = () => {
    setDeleteGroupOpen(false);
    setSelectedGroupId(null);
  };

  const deleteGroupHandler = async () => {
    try {
      await deleteGroup(selectedGroupId);
      setGroups((prevGroups) => prevGroups.filter((group) => group.id !== selectedGroupId));
      setNotificationMessage('Group deleted successfully.');
      setNotificationSeverity('success');
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Something went wrong. We were not able to delete the group. Try again.';
      setNotificationMessage(errorMessage);
      setNotificationSeverity('error');
    }
    handleDeleteGroupClose();
    setNotificationOpen(true);
    // Refresh group list
    fetchGroupsAfterAction();
  };

  const fetchGroupsAfterAction = async () => {
    try {
      const groupsByType = await fetchGroups({
        type: groupTypeMap[selectedGroupType],
        limit: 100,
        page: 1,
      });
      setGroups(groupsByType || []);
      setFilteredGroups(groupsByType || []);
    } catch (err) {
      setError("Error fetching updated groups");
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleSelect = (groupType) => {
    setSelectedGroupType(groupType);
    setIsOpen(false);
    // fetchGroupsAfterAction();
  };

  const onFilter = (query) => {
    const filtered = groups.filter((group) =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredGroups(filtered);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onFilter(query); // Filter immediately as the user types
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-opacity-70 bg-backgroundColor">
        <div className="w-[260px] h-[881px] mt-8 shadow-lg bg-white fixed left-[105px] top-[49px] bottom-2 rounded-[8px] transition-all duration-300 z-30">
          <div className="flex mt-2 justify-between items-center px-4">
            <h3 className="text-lg font-semibold text-gray-800 ">Group</h3>
            <div
              className="text-[30px] text-[#EB7E39] hover:cursor-pointer"
              onClick={handleAddGroupOpen}
            >
              +
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <div className="relative flex-shrink-0">
              <div
                className={`border placeholder:text-[#A9A3A3] border-[#A9A3A3] outline-none border-t border-b border-l-0 border-r-0 pl-6
                  ${selectedGroupType ? 'bg-[#A7D49A] text-[#555555] text-[12px]' : 'bg-white text-[12px] text-[#555555]'}
                  cursor-pointer flex items-center justify-between h-8 w-24`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedGroupType || "Type"}
                {isOpen ? (
                  <UpArrowIcon className="mr-1" />
                ) : (
                  <DownArrowIcon className="mr-1" />
                )}
              </div>
              {isOpen && (
                <div className="absolute mt-1 bg-white border border-borderColorBox rounded shadow-lg w-full">
                  {groupTypes.length > 0 ? (
                    groupTypes.map((g, index) => (
                      <div
                        key={index}
                        onClick={() => handleSelect(g)}
                        className="text-[12px] p-2 hover:bg-[#A7D49A] hover:text-[#555555] cursor-pointer"
                      >
                        {g}
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-[#555555] text-[12px] p-2">
                      No group types
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="relative flex-grow">
              <input
                type="text"
                className="w-full border-l border-[#A9A3A3] placeholder:text-[#A9A3A3] outline-none border-t border-b border-r-0 pr-8 h-8 text-[12px] text-[#A9A3A3]"
                placeholder="   Search Group"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <SearchBoxIcon />
              </div>
            </div>
          </div>

          <div className="overflow-y-auto max-h-[550px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
            {filteredGroups.length === 0 ? (
              <div className="flex flex-col items-center py-20">
                <NoUserIcon className="w-16 h-16 mb-2 text-gray-400" />
                <p className="text-gray-600">No Groups to display</p>
              </div>
            ) : (
              <ul>
                {filteredGroups.map((group) => (
                  <li
                    key={group.id}
                    className="flex text-[12px] border-b border-borderColorBox text-customGray justify-between items-center hover:bg-hoverOrange hover:border-b-0 hover:rounded-md p-2"
                  >
                    <div className='text-customGray text-[12px]/[18px]'>{group.name}</div>
                    <div className="flex gap-x-4">
                      <div onClick={() => handleDeleteGroupOpen(group.id)} className='hover:cursor-pointer'>
                        <DeletePenIcon />
                      </div>
                      <div onClick={() => handleEditGroupOpen(group)} className='hover:cursor-pointer'>
                        <EditPenIcon />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <AddGroup
        open={addGroupOpen}
        handleClose={handleAddGroupClose}
        addGroup={handleAddGroup}
        editGroupPenDetails={handleUpdateGroup}

      />
      <EditGroup
        open={editGroupOpen}
        handleClose={handleEditGroupClose}
        groupId={selectedGroupId}
        editGroupPenDetails={handleUpdateGroup}
        setNotification={(message, severity) => {
          setNotificationMessage(message);
          setNotificationSeverity(severity);
          setNotificationOpen(true);
        }}
      />

      <DeleteGroup
        open={deleteGroupOpen}
        onClose={handleDeleteGroupClose}
        onDelete={deleteGroupHandler}
      />
      <Notification
        open={notificationOpen}
        message={notificationMessage}
        onClose={handleNotificationClose}
        severity={notificationSeverity}
      />
    </>
  );
};

export default GroupPage;
