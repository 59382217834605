import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputField from '../components/InputField';
import Select from '../components/SelectBox';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getApiMap from '../routes/url/ApiUrls';
import { PencilIcon } from '../assets/svgs/index';
import EditDBConnection from './EditDBConnection';

const ViewDBConnection = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [tableOptions, setTableOptions] = useState([]);
    const [dbConnectionData, setDbConnectionData] = useState(null);
    const [message, setMessage] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [valuesSet, setValuesSet] = useState(false); // New state to track if values are set
    const [showForm, setShowForm] = useState(true); // State to control form visibility

    const validationSchema = Yup.object({
        serverType: Yup.string()
            .required('Server type is required')
            .min(1, 'Server type is required'),
        path: Yup.string()
            .min(1, 'Path is required'),
        queryType: Yup.string()
            .required('Query type is required')
            .min(1, 'Query type is required'),
        databaseName: Yup.string()
            .required('Database name is required')
            .min(1, 'Database name is required'),
        userName: Yup.string()
            .min(3, 'Username must be at least 3 characters long')
            .min(1, 'Username is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long')
            .min(1, 'Password is required'),
        port: Yup.string()
            .matches(/^[0-9]+$/, 'Port must be a valid number')
            .min(1, 'Port is required'),
        tableName: Yup.string()
            .required('Table name is required')
            .min(1, 'Table name is required'),
        dateTimeColumn: Yup.string()
            .required('DateTime column name is required')
            .min(1, 'DateTime column name is required'),
        batchNumberColumn: Yup.string()
            .required('Batch number column name is required')
            .min(1, 'Batch number column name is required'),
        jobNumberColumn: Yup.string()
            .required('Job number column name is required')
            .min(1, 'Job number column name is required'),
        Username_dateTimeColumn: Yup.string()
            .required('User name for DateTime column is required')
            .min(1, 'User name for DateTime column is required'),
        Username_batchNumberColumn: Yup.string()
            .required('User name for Batch number column is required')
            .min(1, 'User name for Batch number column is required'),
        Username_jobNumberColumn: Yup.string()
            .required('User name for Job number column is required')
            .min(1, 'User name for Job number column is required'),
    });


    useEffect(() => {


        const fetchDatabaseConnectionData = async () => {
            try {
                const response = await axiosPrivate.get(`${getApiMap("databaseBase")}`);
                setDbConnectionData(response.data);
            } catch (error) {
                console.error('Error fetching database connection data:', error);
            }
        };

        fetchDatabaseConnectionData();
    }, [axiosPrivate]);

    const getDataBaseTables = async () => {
        try {
            const response = await axiosPrivate.get(`${getApiMap("dataBaseTables")}`);
            const tables = response.data.tables.map((table) => ({
                label: table,
                value: table,
            }));
            setTableOptions(tables);
        } catch (error) {
            console.error("Error fetching table options:", error);
        }
    };

    // Fetch table options on component mount
    useEffect(() => {
        getDataBaseTables();
    }, []);


    const formik = useFormik({
        initialValues: {
            serverType: '',
            path: '',
            queryType: '',
            databaseName: '',
            userName: '',
            password: '',
            port: '',
            tableName: '',
            dateTimeColumn: '',
            batchNumberColumn: '',
            jobNumberColumn: '',
            Username_dateTimeColumn: '',
            Username_batchNumberColumn: '',
            Username_jobNumberColumn: '',
        },
        validationSchema,
        enableReinitialize: true,
    });

    const databaseOptions = [
        { label: 'MSAccess', value: 'MSAccess' },
        { label: 'MSSQL', value: 'MSSQL' },
    ];

    const queryOptions = [
        { label: 'MSaccess', value: 'MSaccess' },
        { label: 'SQL Server', value: 'SQL Server' },
    ];

    useEffect(() => {
        if (dbConnectionData && !valuesSet) {
            formik.setValues({
                serverType: dbConnectionData.message.serverType || '',
                path: dbConnectionData.message.path || '',
                queryType: dbConnectionData.message.queryType || '', // Corrected from serverType to queryType
                databaseName: dbConnectionData.message.databaseName || '',
                userName: dbConnectionData.message.userName || '',
                password: dbConnectionData.message.password || '',
                port: dbConnectionData.message.port || '',
                tableName: dbConnectionData.message.tableName || '',
                dateTimeColumn: dbConnectionData.message.dateTimeColumn || '',
                batchNumberColumn: dbConnectionData.message.batchNumberColumn || '',
                jobNumberColumn: dbConnectionData.message.jobNumberColumn || '',
                Username_dateTimeColumn: dbConnectionData.message.Username_dateTimeColumn || '',
                Username_batchNumberColumn: dbConnectionData.message.Username_batchNumberColumn || '',
                Username_jobNumberColumn: dbConnectionData.message.Username_jobNumberColumn || '',
            });
            setValuesSet(true); // Mark values as set
        }
    }, [dbConnectionData, formik, valuesSet]);

    const navigateBack = () => {
        navigate('/');
    };

    const handleOkayClick = () => {
        setShowForm(false); //
    };


    return (
        <>
            {showForm && (

                <div className="fixed inset-0 w-full h-screen flex items-center bg-opacity-80 bg-backgroundColor z-30 justify-center backdrop-blur-[5px]">
                    <div className="w-7/12 bg-white p-8 rounded-md shadow-lg mt-16 overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                        <div className="flex items-center relative">
                            <button onClick={() => { formik.resetForm(); navigateBack(); }} className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl">
                                &times;
                            </button>
                            <div className="text-textHeading2 pt-2 text-[24px]/[28px] font-bold">Connected Details</div>

                            <div
  className="relative cursor-pointer ml-2 group"
  onClick={() => setShowEditModal(true)}
>
  {/* Tooltip Text */}
  <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-black text-white text-xs rounded-md p-2 w-max max-w-xs">
    Edit DB details and connect
  </div>

  {/* Pencil Icon */}
  <PencilIcon />
</div>
                        </div>
                        <div className="text-textGray text-[16px]/[20px] pt-1">Details of connected Database</div>

                        <form className="w-full mt-4">
                            <div className="w-full flex gap-x-4">
                                <div className="w-1/2">
                                    <InputField
                                        label="Path"
                                        type="text"
                                        name="path"
                                        value={formik.values.path}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="MSSQL Server"
                                    />
                                    {/* <Select
                                        label="Query Type"
                                        name="queryType"
                                        options={queryOptions}
                                        value={formik.values.queryType}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            formik.setFieldValue('queryType', selectedValue);

                                            // Update serverType if required
                                            formik.setFieldValue('serverType', selectedValue);
                                        }}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                    /> */}

                                    <Select
                                        label="Database"
                                        name="serverType"
                                        options={databaseOptions}
                                        value={formik.values.serverType}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            formik.setFieldValue('serverType', selectedValue);
                                        }}
                                        error={formik.touched.serverType && formik.errors.serverType}
                                        placeholder="Choose database server"
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    />

                                    <InputField
                                        label="User Name"
                                        type="text"
                                        name="userName"
                                        value={formik.values.userName}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Admin"
                                    />
                                    <InputField
                                        label="Port"
                                        type="text"
                                        name="port"
                                        value={formik.values.port}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="6565"
                                    />
                                    <InputField
                                        label="DateTime Column"
                                        type="text"
                                        name="dateTimeColumn"
                                        value={formik.values.dateTimeColumn}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        label="Batch Number Column"
                                        type="text"
                                        name="batchNumberColumn"
                                        value={formik.values.batchNumberColumn}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        label="Job Number Column"
                                        type="text"
                                        name="jobNumberColumn"
                                        value={formik.values.jobNumberColumn}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                </div>
                                <div className="w-1/2">

                                    <InputField
                                        label="Database Name"
                                        type="text"
                                        name="databaseName"
                                        value={formik.values.databaseName}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Database Name"
                                    />
                                    <InputField
                                        label="Password"
                                        type="password"
                                        name="password"
                                        value={formik.values.password}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="********"
                                    />
                                    <InputField
                                        label="Table name"
                                        name="tableName"
                                        value={formik.values.tableName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.tableName && formik.errors.tableName}
                                        placeholder="Table1"
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    />
                                    <InputField
                                        label="Username (DateTime Column)"
                                        type="text"
                                        name="Username_dateTimeColumn"
                                        value={formik.values.Username_dateTimeColumn}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        label="Username (Batch Number Column)"
                                        type="text"
                                        name="Username_batchNumberColumn"
                                        value={formik.values.Username_batchNumberColumn}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        label="Username (Job Number Column)"
                                        type="text"
                                        name="Username_jobNumberColumn"
                                        value={formik.values.Username_jobNumberColumn}
                                        readOnly
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                </div>
                            </div>
                            <div className='flex w-full gap-x-4 mt-2 justify-end'>
                                <button type='button' className='w-24 h-10 flex justify-center items-center border border-borderColorBox rounded-md text-customGray text-[14px]/[24px]' onClick={navigateBack}>Cancel</button>
                                <button
                                    type="button"
                                    onClick={handleOkayClick} // Handle the okay button click
                                    className={`flex justify-center items-center py-2 px-1 h-[44px] bg-baseOrange w-[74px] text-[16px] font-semibold text-white rounded-md }`}
                                >
                                    Okay
                                </button>
                            </div>
                            {message && <p className="mt-4 text-center text-red-500">{message}</p>}
                        </form>

                        {showEditModal && (
                            <EditDBConnection
                                dbConnectionData={dbConnectionData}
                            />
                        )}
                    </div>
                </div>
            )}


        </>
    );
};

export default ViewDBConnection;
