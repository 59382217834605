import React, { useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const Index = ({data,yAxisFormat,minValues, listData,showLogScale,handleShowLogScale,maxValue,handleMinValueChange,handleMaxValueChange,handleYAxisFormatChange}) => {
    const[isOpen,setIsOpen]=useState(false)
    const handleOpen = ()=>{
        setIsOpen(!isOpen)
    } 
  return (
    <div className={`flex-col w-full mt-2`}>
            <div className='flex justify-between w-full items-center'>
        <div className='flex gap-x-2'>
            <div className='rounded-full w-4 h-4' style={{ backgroundColor: `${data.color}` }}></div>
            <div className='underline text-[12px]/[14px] font-medium text-[#1E1B39]'>Master Batch</div>
        </div>
        <div className='text-[12px]/[20px] text-[#737373]'>{data.batchNumber}</div>
    </div>
        <div className='flex justify-between items-center mt-2'><div className='text-[12px]/[14px] text-[#1E1B39] font-medium'>
            Selected Parameter
        </div>
        <div onClick={handleOpen} className='cursor-pointer'>{isOpen?<KeyboardArrowUpIcon className='text-[#737373] '/>:<ChevronRightIcon className='text-[#737373] w-2 h-2'/>}</div></div>
        <div>

{isOpen&&<>       <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="minValue" className="text-[12px]/[20px] text-[#555555]">Name</label>
            <input 
                type="text" 
                className="border rounded-sm w-9/12 text-[12px] opacity-80 h-4/6 text-[#979797] pl-1 bg-[#F0F5EC] focus:outline:none" 
                placeholder="Enter minimum value"
                value={listData}
                disabled={true}

            />
        </div>    <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="minValue" className="text-[12px]/[20px] text-[#555555]">Min Value</label>
            <input 
                id="minValue" 
                type="text" 
                className="border rounded-sm w-4/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] focus:outline:none" 
                placeholder="Enter minimum value"
                value={minValues||""}
                onChange={handleMinValueChange} // Handle change accordingly
            />
        </div>

        <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="maxValue" className="text-[12px]/[20px] text-[#555555]">Max Value</label>
            <input 
                id="maxValue" 
                type="text" 
                className="border rounded-sm w-4/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] focus:outline-none" 
                placeholder="Enter maximum value" 
                value={maxValue}
                onChange={handleMaxValueChange} // Handle change accordingly
            />
        </div>


        <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="yAxisFormat" className="text-[12px]/[20px] text-[#555555]">Format</label>
            <select 
                id="yAxisFormat"
                className="border rounded-sm w-6/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] focus:outline-none" 
                value={yAxisFormat}
                onChange={handleYAxisFormatChange} // Handle change accordingly
            >
                <option value="linear">Linear</option>
                <option value="exponential">Exponential</option>
                <option value="logarithmic">Logarithmic</option>
            </select>
        </div>
        <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
            <label htmlFor="maxValue" className="text-[12px]/[20px] text-[#555555]">Log Scale</label>
            <input 
    id="maxValue" 
    type="checkbox" 
    className="w-4 h-4 text-[#000000] pl-1 bg-[#F0F5EC] border-2 border-[#000000] " 
    checked={showLogScale} 
    onChange={handleShowLogScale} 
/>
        </div></>}
        </div>
    </div>

  )
}

export default Index