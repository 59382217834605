import React, { useEffect, useState } from 'react'
import getAPIMap from '../../routes/url/ApiUrls'
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate'

const EditPens = ({ penId, setSearchParams, setShowModal, setModalContext }) => {
    const axiosPrivate = useAxiosPrivate()
    const [prevName, setPrevName] = useState("")
    const [newName, setNewName] = useState("")
    const [pendId, setPenId] = useState(null)

    useEffect(() => {
        const getPenById = async () => {
            const response = await axiosPrivate.get(`${getAPIMap("penlist")}/${penId}`)
            setPrevName(response.data.pen_name)
        }
        getPenById()
    }, [])
    const closeModal = () => {
        setSearchParams({ action: "view" })
    }
    const handleSubmit = async () => {
        try {
            const data = { "pen_name": newName }
            const response = await axiosPrivate.put(`${getAPIMap("penlist")}/${penId}`, data)
            console.log(response)
            if (response.status === 200) {
                setShowModal(true)
                setModalContext({
                    text: "Pen details edited successfully. Check the pen list.",
                    title: "",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='w-full h-screen flex justify-center items-center bg-opacity-70 bg-backgroundColor'>
            <div className='w-4/12 bg-white h-80 rounded-md shadow-lg'>
                <div className='flex justify-between items-center px-8 pt-6'>
                    <h1 className='text-textHeading font-semibold text-[16px]/[28px]'>Edit Pen Name</h1>
                    <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">
                        &times;
                    </button>
                </div>
                <div className='text-[12px]/[20px] px-8 text-textGray mb-4'>
                    Choose a unique name for the pen.
                </div>
                <div className="px-8">
                    <label className='text-[14px]/[20px] text-textHeading font-semibold'>Name<span className='text-red-600'>*</span></label>
                    <input
                        type="text"
                        value={prevName}
                        onChange={(e) => setNewName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Enter new pen name"
                        disabled={true} // Disable input for previous name
                    />
                </div>
                <div className="px-8 pt-4">
                    <label className='text-[14px]/[20px] text-textHeading font-semibold'>New Name<span className='text-red-600'>*</span></label>
                    <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Enter a user-friendly name"
                    />
                </div>
                <div className="px-8 py-4 gap-x-4 flex justify-end">
                    <button className="px-4 py-2 border border-borderColorBox bg-white text-[#0F172A] hover:outline-none rounded-md" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className={`px-4 py-2 bg-baseOrange text-white rounded-md font-medium transition duration-300 ${!newName ? 'bg-gray-400 cursor-not-allowed' : ''}`}
                        onClick={handleSubmit}
                        disabled={!newName}                     >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditPens
