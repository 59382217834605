import { PATHS } from "../path"
import UserPage from "../../components/User"
import GroupPage from "../../components/GroupComps"
import DBConnection from "../../pages/DBConnection"
import Penlist from '../../pages/Penlist'
import BatchDetails from "../../pages/BatchDetails"
import ViewDBConnection from "../../pages/ViewDBConnection"
import { Group } from "@mui/icons-material"
import Home from "../../pages/Home"
export const adminRoutes = [
    {
        path: '/user',
        component: () => (
            <>
                <BatchDetails />
                <UserPage />
            </>
        ),
        name: "User add and acess",
        exact: true
    },
    {
        path: '/db',
        component: () => (
            <>
                <BatchDetails />
                <DBConnection />
            </>
        ),
        name: "DB connection page",
        exact: true
    },
    {
        path: '/pen',
        component: () => (
            <>
                <BatchDetails />
                <Penlist />
            </>
        ),
        name: "Penlist page",
        exact: true
    }, {
        path: '/',
        component: Home,
        name: "Batch details page",
        exact: true
    },
    {
        path: '/group',
        component: () => (
            <>
                <BatchDetails />
                <GroupPage />
            </>
        ),
        name: "Group page",
        exact: true
    },
    {
        path: '/viewdb',
        component: () => (
            <>
                <BatchDetails />
                <ViewDBConnection />
            </>
        ),
        name: "Group page",
        exact: true
    },

]
