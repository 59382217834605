import React from "react";
import ImageLight from "../assets/img/login-office.png";
import Logo from "../assets/img/symbiotic-logo 1.png";
import LoginForm from "../components/Forms/LoginForm";

function Login() {
  return (
    <div className="flex h-screen bg-background-gradient">

      <div className="flex items-center justify-center w-full md:w-6/12 px-6">
        <div className=" w-7/12">
          <div className="flex justify-left mb-1">
            <img className="h-12 w-auto" src={Logo} alt="Symbiotic Logo" />
          </div>

          <h1 className="mb-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
            Welcome User!
          </h1>
          <LoginForm />
        </div>

      </div>

      {/* Right section - Image */}
      <div className="hidden md:block w-1/2">
        <img
          aria-hidden="true"
          className="object-cover w-full h-full"
          src={ImageLight}
          alt="Office"
        />
      </div>
      <div className="fixed bottom-2 left-4 text-black text-[12px] flex items-center justify-center gap-x-2"> Powered by <div className="w-4 h-4 rounded-full bg-black"></div> Symbiotic automation system</div>
    </div>
  );
}

export default Login;
