import React from 'react';

const InputField = ({ label, type = 'text', value, onChange, error, name, labelClass = '', inputClass = '', placeholder = '' }) => {
    return (
        <div className="w-full mb-4">
            <label
                htmlFor={name}
                className={`block text-sm font-medium text-gray-700 mb-2 ${labelClass}`}
            >
                {label}
            </label>

            <input
                id={name}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={`w-full p-2 border rounded-md focus:outline-none
                    ${error
                        ? 'border-red-500'
                        : 'border-gray-300 hover:border-[#EB7E39] focus:border-[#EB7E39]'}
                    ${inputClass}`
                    } />

            {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
        </div>
    );
};

export default InputField;
