import { useEffect, useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { PATHS } from "../path";

const PublicWrapper = () => {
    const { auth } = useContext(AuthContext);
    const location = useLocation();

    const isAuthenticated = false
    useEffect(() => {
        if (isAuthenticated) {
            window.location.href = PATHS.documentSearchTrain;
        }
    }, [isAuthenticated]);

    if (isAuthenticated) {
        return null;
    }

    if (!isAuthenticated && location.pathname === "/") {
        return <Navigate to="/sign-in" />;
    }

    return <Outlet />;
};

export default PublicWrapper;
