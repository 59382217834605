import { useContext, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import axios from "../api/apiBaseUrl";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, setSessionExpired, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {

        if (auth) {
            let accessToken =
                auth?.accessToken || localStorage.getItem("accessToken");
            const requestIntercept = axios.interceptors.request.use(
                (config) => {
                    if (!config.headers["Authorization"]) {
                        config.headers["Authorization"] = `Bearer ${accessToken}`;
                    }
                    return config;
                },
                (error) => Promise.reject(error)
            );
            const responseIntercept = axios.interceptors.response.use(
                (response) => response,
                async (error) => {
                    const prevRequest = error?.config;
                    // debugger;
                    if (error?.response?.status === 409) {
                        return error?.response;
                    }
                    if (error?.response?.status === 401) {
                        setAuth("");
                        await localStorage.removeItem("accessToken");
                        await localStorage.removeItem("refreshToken");
                        navigate("/sign-in");
                        return;
                    }

                    if (error?.response?.status === 401 && !prevRequest?.sent) {
                        prevRequest.sent = true;

                        try {
                            const newAccessToken = await refresh();
                            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                            return axios(prevRequest);
                        } catch (refreshError) {
                            if (refreshError.response?.status === 401) {
                                console.log("Refresh token expired or invalid, logging out...");
                                setSessionExpired(true);
                            }
                            return Promise.reject(refreshError);
                        }
                    }
                    return Promise.reject(error);
                }
            );

            return () => {
                axios.interceptors.request.eject(requestIntercept);
                axios.interceptors.response.eject(responseIntercept);
            };
        }
    }, [auth, refresh, setSessionExpired]);

    return axios;
};
