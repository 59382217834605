import { createContext, useState, useEffect } from "react";
import axios from "../api/apiBaseUrl";
import { jwtDecode } from "jwt-decode";
import getApiMap from "../../src/routes/url/ApiUrls";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [role, setRole] = useState("");
  const navigate = useNavigate()
  const [initialLoader, setInitialLoader] = useState(true);
  const [auth, setAuth] = useState({});
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || "");
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const setProfileData = async (token) => {
    const accessToken = token || localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token available.");
      return;
    }

    try {
      const decoded = jwtDecode(accessToken);

      const res = await axios.get(
        getApiMap("getUserById").replace(":id", decoded.sub),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(res)

      setAuth((prevAuth) => ({
        ...prevAuth, // Maintain previous auth data
        ...res.data, // Add fetched user data
      }));
    } catch (error) {
      console.error("Error fetching user info:", error.message);
    } finally {
      setTimeout(() => setInitialLoader(false), 1000);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        getApiMap("loginURL"),
        { name: email, password },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const token = response.data.token.token;
      const refreshToken = response.data.refreshToken.token;
      localStorage.setItem("accessToken", token);
      setAccessToken(token);
      localStorage.setItem("refreshToken", refreshToken);

      await setProfileData(token);
      return response;
    } catch (error) {
      console.error("Login failed:", error.message);
      throw error;
    } finally {
      setInitialLoader(false)
    }
  };

  const logout = async () => {
    const token = localStorage.getItem("refreshToken") || auth?.refreshToken
    try {
      const response = await axios.post(getApiMap("logout"), { "refreshToken": token },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  const addNewUser = async (userDetails) => {
    try {
      const response =
        await axios.post('/users', userDetails,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      console.log("User added successfully:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
      } else {
        console.error("Failed to add user:", error.message);
      }
      throw error;
    }
  };

  const fetchUsersByRole = async (roleId) => {
    try {
      const apiUrl = getApiMap("getUserByRole");

      const response = await axios.get(`${apiUrl}/${roleId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response?.data) {
        return response.data;
      } else {
        console.warn("No data found for the specified role ID.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching users by role:", error.message);
    }
  };

  const editUser = async (userId, updatedDetails) => {
    try {
      const response = await axios.put(
        getApiMap("updateUserById").replace(":id", userId),
        updatedDetails,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("User updated successfully:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
      } else {
        console.error("Failed to update user:", error.message);
      }
      throw error;
    }
  };

  const getUserDetailsById = async (userId) => {
    try {

      const response = await axios.get(
        getApiMap("getUserById").replace(":id", userId), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      throw error;
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(getApiMap("getRoles"), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setRole(response.data.result.roleList);
    } catch (error) {
      console.error("Failed to fetch roles:", error.message);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axios.delete(getApiMap("deleteUser").replace(":id", userId), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(`User with ID ${userId} deleted successfully.`);
      return userId;
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
      } else {
        console.error("Failed to delete user:", error.message);
      }
      throw error;
    }
  };

  const addNewGroup = async (groupDetails) => {
    console.log("groupDetails", groupDetails)
    try {
      const response =
        await axios.post('/groups', groupDetails,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      console.log("Groups added successfully:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
      } else {
        console.error("Failed to add groups:", error.message);
      }
      throw error;
    }
  };

  const editGroup = async (groupId, updatedgroupDetails) => {
    console.log("Payload to be sent:", updatedgroupDetails);

    try {
      const apiUrl = getApiMap("updateGroupById").replace(":id", groupId);
      console.log("API Endpoint:", apiUrl);

      const response = await axios.put(apiUrl, updatedgroupDetails, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("API Response Data:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);
      } else {
        console.error("Failed to update Group:", error.message);
      }
      throw error;
    }
  };

  const getGroupDetailsById = async (groupId) => {
    try {
      const response = await axios.get(
        getApiMap("getGroupById").replace(":id", groupId),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch group details:", error);
      throw error;
    }
  };

  const deleteGroup = async (groupId) => {
    try {
      await axios.delete(getApiMap("deleteGroup").replace(":id", groupId), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log(`Group with ID ${groupId} deleted successfully.`);
      return groupId;

    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
      } else {
        console.error("Failed to delete group:", error.message);
      }
      throw error;
    }
  };

  const fetchGroupTypes = async ({ limit = 30, page = 1 }) => {
    try {
      const url = `${getApiMap("getGroupType")}?limit=${limit}&page=${page}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching groups:", error.message);
      return null;
    }
  };

  const fetchGroups = async ({ sortBy = "name", type, limit = 100, page = 1 } = {}) => {
    try {
      const apiUrl = getApiMap("getGroups");
      const apiEndpoint = `${apiUrl}?sortBy=${sortBy}&type=${type}&limit=${limit}&page=${page}`;
      const response = await axios.get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response?.data) {
        return response.data;
      } else {
        console.warn("No groups found for the specified criteria.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching groups:", error.message);
      return null;
    }
  };

  const fetchPen = async () => {
    try {
      const response = await axios.get(getApiMap("penlist"), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch roles:", error.message);
      return null;
    }
  };

  const UpdateGroupPenDetail = async (penId, updatedPen) => {
    try {
      const response = await axios.put(
        getApiMap("updateGroupPenDetail").replace(":id", penId),
        updatedPen,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("response.data", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to update group pen details:", error);
      throw error;
    }
  };



  const value = {
    login,
    addNewUser,
    editUser,
    fetchRoles,
    fetchUsersByRole,
    getUserDetailsById,
    deleteUser,
    addNewGroup,
    editGroup,
    fetchGroups,
    fetchGroupTypes,
    getGroupDetailsById,
    deleteGroup,
    fetchPen,
    UpdateGroupPenDetail,
    role,
    initialLoader,
    setInitialLoader,
    setProfileData,
    auth,
    setAuth,
    logout,
    sessionExpired,
    setSessionExpired,
  };



  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
